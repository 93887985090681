export
function create() {

    var config = {
        base : "pie"
    };

    // config = mergeConfig(config, userConfig);

    function mergeConfig(config, user){
        return Object.assign(config, user);
    }

    var publicAPI = {
        config : config
    };

    publicAPI.mergeConfig = function (newConfig) {
        // return newConfig;
        this.config = mergeConfig(this.getConfig(), newConfig);
        return config;
    };
    publicAPI.getConfig = function getConfig() {
        return this.config;
    };

    
    return publicAPI;
}