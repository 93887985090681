import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { flexMap } from '../../maps/flexMap';

const StyledButtonGroup = styled.div`

    padding: ${ props => props.uiScheme.padding.buttonGroup ? props.uiScheme.padding.buttonGroup : '1vh' };
    
    justify-content: space-between;
    display: flex;
    flex-direction: ${ props => props.orientation ? flexMap[props.orientation] : 'row' };
    

    & > button {

        margin-right: ${ props => props.uiScheme.margin.buttonGroup ? props.uiScheme.margin.buttonGroup : '1vh' };

        &:last-child {

            margin-right: 0;

        }

        ${
            props => props.sizing === 'shrink' && css`

                flex-grow: 0;
                flex-shrink: 1;
                flex-basis: auto;

            `
        }

        ${
            props => props.sizing === 'grow' && css`

                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: 0%;

            `
        }

    }

`;


export default
function ButtonGroup( props ) {

    // props.isActive

    const [ isHover, setIsHover ] = useState( false );

    return (
        <>
            <StyledButtonGroup

                uiScheme={ props.uiScheme }
                orientation={ props.orientation }
                sizing={ props.sizing }
                
            >

                { props.children }
                    
            </StyledButtonGroup>
        </>
    );

}