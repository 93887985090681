import React, { useState } from 'react';
import styled from 'styled-components';

import Button from './ButtonBASE';

const StyledImage = styled.img`

    height : ${ props => props.size ? props.size : '24px' };
    width : ${ props => props.size ? props.size : '24px' };
    object-fit: contain;

`;


export default
function ButtonImage( props ) {

    // props.isActive

    return (

        <>
        
            <Button

                forwardedKey={ props.forwardedKey }
                onClick={ () => props.onClick() }
                isActive={ props.isActive }
                uiScheme={ props.uiScheme }
                buttonStyle={ props.buttonStyle }
                orientation={ props.orientation }
                title={ props.title }
                extraClassName={ props.extraClassName }
                tabStyle={ props.tabStyle }
                grow={ props.grow }
                
            >

                <StyledImage

                    size={ props.img.size }
                    src={ props.img.src }
                    alt="" // empty for decorative WCAG

                />

                { props.children }
                    
            </Button>
            
        </>

    );

}