import React, { useEffect, useRef } from 'react';

import { createPortal } from 'react-dom';

export default
    function PortalFromDOM(props) {

    const elRef = useRef(null);

    if (!elRef.current) {
        elRef.current = props.portalDOM;
    }

    useEffect(() => {


        return () => elRef.current.innerHTML = '';

    }, []);

    return createPortal(<>{props.children}</>, elRef.current);
}