import savedLoss from './hls/your_saved_hearing_loss.svg';
import yourLoss from './hls/your_hearing_loss.svg';
import hearingAid from './hls/gram_hearing.svg';

import experimentation from './icons/experimentation.svg';
import headphones_L from './icons/headphones-L.svg';
import headphones_R from './icons/headphones-R.svg';
import headphones from './icons/headphones.svg';
import hearing_loss_simulator from './icons/hearing-loss-simulator.svg';
import hearing_loss_toolbox from './icons/hearing-loss-toolbox.svg';
import hearingaid_graph from './icons/hearingaid-graph.svg';
import loop_graph from './icons/loop-graph.svg';
import mild_graph from './icons/mild-graph.svg';
import moderate_graph from './icons/moderate-graph.svg';
import normal_graph from './icons/normal-graph.svg';
import programme_examples from './icons/programme-examples.svg';
import programme_hearing_aid from './icons/programme-hearing-aid.svg';
import programme_my_hearing_loss from './icons/programme-my-hearing-loss.svg';
import sandbox from './icons/sandbox.svg';
import rubella_graph from './icons/rubella-graph.svg';
import severe_graph from './icons/severe-graph.svg';
import chevron_right from './icons/chevron-right.svg';
import earLeft from './icons/ear-left.svg';
import earRight from './icons/ear-Right.svg';

import LosseiverSmall from './losseiver/losseiver-logo-stripped-01.svg';

const assetObj = {
    'savedLoss' : savedLoss,
    'yourLoss' : yourLoss,
    'hearingAid' : hearingAid,
    experimentation : experimentation,
    headphones_L : headphones_L,
    headphones_R : headphones_R,
    headphones : headphones,
    hearing_loss_simulator : hearing_loss_simulator,
    hearing_loss_toolbox : hearing_loss_toolbox,
    hearingaid_graph : hearingaid_graph,
    loop_graph : loop_graph,
    mild_graph : mild_graph,
    moderate_graph : moderate_graph,
    normal_graph : normal_graph,
    programme_examples : programme_examples,
    programme_hearing_aid : programme_hearing_aid,
    programme_my_hearing_loss : programme_my_hearing_loss,
    programme_examples : programme_examples,
    programme_hearing_aid : programme_hearing_aid,
    programme_my_hearing_loss : programme_my_hearing_loss,
    sandbox : sandbox,
    rubella_graph : rubella_graph,
    severe_graph : severe_graph,
    assetProgrammesMyHearingLoss : programme_my_hearing_loss,
    assetProgrammesExamples : programme_examples,
    assetProgrammesHearingAid : programme_hearing_aid, 
    assetProgrammesSandbox : experimentation,
    chevron_right : chevron_right,
    earLeft : earLeft,
    earRight : earRight,

    LosseiverSmall : LosseiverSmall,
};

export const AssetMap = {
    ...assetObj,
    getAsset : function getAsset(asset) {
        return assetObj[ asset ];
    },
    get : function get(asset) {
        return assetObj[ asset ];
    }
};

export default AssetMap;