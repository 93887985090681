import { useState, useEffect } from 'react';

import useFetchJSON from './useFetchJSON';



export
default function useUser() {


    //TODO make better

    
    const losseiverwp = window.losseiverwp;



    const apiUrl = '/wp-json/hlt/v1';



    //DEV - LIVE
    // //Live
    // const wpUser = useFetchJSON(`${losseiverwp['site-url']}${apiUrl}/user`);

    // dev
    const wpUser = {
        name : "Jack Barnett",
        isUser : true,
        hasInit : true,
        isAudiogramUser : true,
        soundBox : {
            saved : false,
        }
    }

    




    //DEV - LIVE

    // //Live
    // let audiogram = JSON.parse(useFetchJSON(`${losseiverwp['site-url']}${apiUrl}/audiogram`));

    // Dev
    

    let audiograms = new Map();


    audiograms.set( 'my-unaided', {
        left : {
            125  : 0,
            250  : -60,
            500  : -80,
            1000 : -90,
            2000 : -90,
            4000 : -100,
            8000 : -90,
        },
        right : {
            125  : 0,
            250  : -60,
            500  : -70,
            1000 : -90,
            2000 : -90,
            4000 : -100,
            8000 : -90,
        }
    });
    audiograms.set( 'my-aided', {
        left : {
            125  : 0,
            250  : -40,
            500  : -45,
            1000 : -40,
            2000 : -40,
            4000 : -60,
            8000 : -60,
        },
        right : {
            125  : 0,
            250  : -45,
            500  : -45,
            1000 : -40,
            2000 : -40,
            4000 : -50,
            8000 : -45,
        }
    });
    audiograms.set( 'sound-box-1', {
        left : {
            125  : 0,
            250  : 0,
            500  : 0,
            1000 : 0,
            2000 : 0,
            4000 : 0,
            8000 : 0,
        },
        right : {
            125  : 0,
            250  : 0,
            500  : 0,
            1000 : 0,
            2000 : 0,
            4000 : 0,
            8000 : 0,
        }
    });
    audiograms.set( 'sound-box-2', {
        left : {
            125  : 0,
            250  : 0,
            500  : 0,
            1000 : 0,
            2000 : 0,
            4000 : 0,
            8000 : 0,
        },
        right : {
            125  : 0,
            250  : -50,
            500  : 0,
            1000 : 0,
            2000 : -89,
            4000 : 0,
            8000 : 0,
        }
    });
    audiograms.set( 'sound-box-3', {
        left : {
            125  : 0,
            250  : 0,
            500  : 0,
            1000 : -45,
            2000 : 0,
            4000 : 0,
            8000 : 0,
        },
        right : {
            125  : 0,
            250  : 0,
            500  : 0,
            1000 : 0,
            2000 : 0,
            4000 : 0,
            8000 : 0,
        }
    });
    audiograms.set( 'sound-box-4', {
        left : {
            125  : 0,
            250  : 0,
            500  : 0,
            1000 : -40,
            2000 : 0,
            4000 : 0,
            8000 : 0,
        },
        right : {
            125  : 0,
            250  : 0,
            500  : 0,
            1000 : 0,
            2000 : 0,
            4000 : 0,
            8000 : 0,
        }
    });
    

    useEffect(() => {
        async function loadUser() {
            // setIsUser(wpUser);


            // const newbean = await setAudiogram({audiogram : 'bubs'});

            
        }
        loadUser();
    }, [wpUser, audiograms]);



    const sendData = async function sendData(url, data) {


        const fetchOptions = {
            method: 'post',
            mode : 'cors',
            headers: {
                'Access-Control-Allow-Origin' : '*',
                'Content-Type': 'application/json',  // sent request
                'Accept':       'application/json'  
            },
            // body : data
            body : JSON.stringify(data)
        };



        if(losseiverwp) {
            fetchOptions.headers['X-WP-Nonce'] = losseiverwp['wp-nonce'];
        }

        
        
        const response = await fetch(url,fetchOptions);

        if(!response.ok) {
            // oups! something went wrong
            return false;
        }
        
        const json = await response.json();
        return json;
    }

    const getAudiogram = function getAudiogram( name ) {
        return audiograms.get(name);
    }

    const setAudiogram = async function setAudiogram(name, data) {
        
        audiograms.set( name, data );
        const theAudiogram = {'audiogram' : data};

        const response = await sendData(`${losseiverwp['site-url']}${apiUrl}/setaudiogram`, theAudiogram);
        
        return response;
    }


    const User = {
        
        name : wpUser ? wpUser.name :'',
        isUser : wpUser ? wpUser.isUser : false,
        hasInit : wpUser ? wpUser.hasInit : false,
        getAudiogram : getAudiogram,
        setAudiogram : setAudiogram,

    };

    return User;
}