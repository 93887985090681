import { css } from 'styled-components';
export
const draculaBuffy = {

    variables : css`
        --loss-background: hsl(290, 15%, 15%);
        --loss-text: hsl(60, 30%, 96%);

        --loss-accent-primary: hsl(330, 100%, 75%);


        --loss-cyan : hsl(170, 100%, 75%);
        --loss-green : hsl(115, 100%, 75%);
        --loss-orange : hsl(35,  100%, 75%);
        --loss-pink : hsl(330, 100%, 75%);
        --loss-purple : hsl(250, 100%, 75%);
        --loss-red : hsl(10,  100%, 75%);
        --loss-yellow : hsl(60,  100%, 75%);

    `,

    background : 'hsl(290, 15%, 15%)',
    text : 'hsl(60, 30%, 96%)',

    selection : {
        background : 'hsl(290, 15%, 30%)',
    },

    textShadow : ( colour ) => `-1px 1px 0 ${ colour }`,
    boxShadow : ( colour ) => `-3px 3px 0 0 ${ colour }`,

    padding : {

        buttons : '1vh',
        buttonGroup : '1vh',
        
    },

    margin : {

        buttonGroup : '1vh',

    },

    buttons : {

        default : {

            background : '#fff',
            text : '#222',
            border : '#222',
            textShadow : '#E7E7E7',
            boxShadow : '#E7E7E7',

            title : {
                
                background : '#222',
                text : '#fff',

            },

            hover : {
                background : '#fff',
                text : '#222',
                border : '#222',
                textShadow : '#E7E7E7',
                boxShadow : '#E7E7E7',

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

            },

            active : {
                background : '#fff',
                text : '#222',
                border : '#222',
                textShadow : '#E7E7E7',
                boxShadow : '#E7E7E7',

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

                hover : {
                    background : '#fff',
                    text : '#222',
                    border : '#222',
                    textShadow : '#E7E7E7',
                    boxShadow : '#E7E7E7',

                    title : {
                
                        background : '#222',
                        text : '#fff',
        
                    },
                },

            },

        },

        play : {

            background : '#47b157',
            text : '#fff',
            border : '#47b157', 
            textShadow : "#1e7847",
            boxShadow : "#1e7847",

            title : {
                
                background : '#222',
                text : '#fff',

            },

            hover : {

                background : '#47b157',
                text : '#fff',
                border : '#47b157', 
                textShadow : "#1e7847",
                boxShadow : "#1e7847",

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

            },

            active : {

                background : '#47b157',
                text : '#fff',
                border : '#47b157', 
                textShadow : "#1e7847",
                boxShadow : "#1e7847",

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

                hover : {

                    background : '#47b157',
                    text : '#fff',
                    border : '#47b157', 
                    textShadow : "#1e7847",
                    boxShadow : "#1e7847",

                    title : {
                
                        background : '#222',
                        text : '#fff',
        
                    },
                    
                }
                
            }
            
        },
        
        repeat : {

            background : '#ffb347',
            text : '#fff',
            border : '#ffb347',
            textShadow : "#eb9d2f",
            boxShadow : "#eb9d2f",

            title : {
                
                background : '#222',
                text : '#fff',

            },

            hover : {

                background : '#eeb347',
                text : '#fff',
                border : '#47b157', 
                textShadow : "#eb9d2f",
                boxShadow : "#eb9d2f",

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

            },

            active : {

                background : '#47b157',
                text : '#fff',
                border : '#47b157', 
                textShadow : "#1e7847",
                boxShadow : "#1e7847",

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

                hover : {

                    background : '#47b157',
                    text : '#fff',
                    border : '#47b157', 
                    textShadow : "#1e7847",
                    boxShadow : "#1e7847",

                    title : {
                
                        background : '#222',
                        text : '#fff',
        
                    },
                    
                }
                
            }
            
        },
        repeat : {

            background : '#ffb347',
            text : '#fff',
            border : '#ffb347',
            textShadow : "#eb9d2f",
            boxShadow : "#eb9d2f",

            title : {
                
                background : '#222',
                text : '#fff',

            },

            hover : {

                background : '#eeb347',
                text : '#fff',
                border : '#47b157', 
                textShadow : "#eb9d2f",
                boxShadow : "#eb9d2f",

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

            },

            active : {

                background : '#47b157',
                text : '#fff',
                border : '#47b157', 
                textShadow : "#1e7847",
                boxShadow : "#1e7847",

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

                hover : {

                    background : '#47b157',
                    text : '#fff',
                    border : '#47b157', 
                    textShadow : "#1e7847",
                    boxShadow : "#1e7847",

                    title : {
                
                        background : '#222',
                        text : '#fff',
        
                    },
                    
                }
                
            }
            
        },
        
        restart : {

            background : '#f5f5f5',
            text : "#333333",
            border : "#f5f5f5",
            textShadow : "#eb9d2f",
            boxShadow : "#eb9d2f",

            title : {
                
                background : '#222',
                text : '#fff',

            },

            hover : {

                background : "#ee6961",
                text : '#fff',
                border : '#47b157', 
                textShadow : "#b9b8b8",
                boxShadow : "#b9b8b8",

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

            },

            active : {

                background : null,
                text : null,
                border : null,
                textShadow : null,
                boxShadow : null,

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

                hover : {

                    background : null,
                    text : null,
                    border : null,
                    textShadow : null,
                    boxShadow : null,

                    title : {
                
                        background : '#222',
                        text : '#fff',
        
                    },
                    
                }
                
            }
            
        },
        
        audioSource : {

            background : '#f5f5f5',
            text : "#333333",
            border : "#f5f5f5",
            textShadow : "#eb9d2f",
            boxShadow : "#eb9d2f",

            title : {
                
                background : '#222',
                text : '#fff',

            },

            hover : {

                background : "#ee6961",
                text : '#fff',
                border : '#47b157', 
                textShadow : "#b9b8b8",
                boxShadow : "#b9b8b8",

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

            },

            active : {

                background : null,
                text : null,
                border : null,
                textShadow : null,
                boxShadow : null,

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

                hover : {

                    background : null,
                    text : null,
                    border : null,
                    textShadow : null,
                    boxShadow : null,

                    title : {
                
                        background : '#222',
                        text : '#fff',
        
                    },
                    
                }
                
            }
            
        },
        
        choice : {

            background : '#f5f5f5',
            text : "#333333",
            border : "#f5f5f5",
            textShadow : "#eb9d2f",
            boxShadow : "#eb9d2f",

            title : {
                
                background : '#222',
                text : '#fff',

            },

            hover : {

                background : "#ee6961",
                text : '#fff',
                border : '#47b157', 
                textShadow : "#b9b8b8",
                boxShadow : "#b9b8b8",

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

            },

            active : {

                background : null,
                text : null,
                border : null,
                textShadow : null,
                boxShadow : null,

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

                hover : {

                    background : null,
                    text : null,
                    border : null,
                    textShadow : null,
                    boxShadow : null,

                    title : {
                
                        background : '#222',
                        text : '#fff',
        
                    },
                    
                }
                
            }
            
        },

        

    },

    audiogram : {

        normal : 'green',
        mild: 'blue',
        moderate: 'blue',
        high     : 'red',
        severe : 'black'

    },

}