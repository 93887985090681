import { create as createAudioNode } from './base-audio.js';

export
function create(audioEngine) {

    var config = {};
    
    var audioNode = createAudioNode(audioEngine);
    audioNode.mergeConfig( config );

    // Inherit methods
    
    // Add methods
    var publicAPI = Object.create(audioNode);
    
    // merge configs

	return publicAPI;
}


