import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { flexMap } from '../../maps/flexMap';

const StyledButton = styled.button`

    display: flex;
    padding: ${ props => props.uiScheme.padding.buttons };
    flex-direction: ${ props => props.title ? 'column' : props.orientation ? flexMap[props.orientation] : 'row' };
    justify-content: center;
    align-items: center;

    ${ props => props.uiScheme.buttons[ props.buttonStyle ]['font-size'] && css`

        font-size: ${ props.uiScheme.buttons[ props.buttonStyle ]['font-size'] }
    
    `}

    border-style : solid;
    border-width: 4px;
    border-radius : ${ props => props.uiScheme ? props.uiScheme.buttons.default[ 'borderRadius' ] : 0 };

    ${ props => props.grow && css`

        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0%;
    
    `}
    
    ${ props => props.title && css`

        padding: 0;
        justify-content: space-between;
        
    `};

    ${ 
        
        props => props.isActive 
        ? css`

            background: ${ props => props.uiScheme ? props.uiScheme.buttons[ props.buttonStyle ].background : 'red' };
            color: ${ props => props.uiScheme ? props.uiScheme.buttons[ props.buttonStyle ].text : 'white' };
            border-color: ${ props => props.uiScheme ? props.uiScheme.buttons[ props.buttonStyle ].active.border : 'white' };
            text-shadow: ${ props => props.uiScheme ? props.uiScheme.textShadow( props.uiScheme.buttons[ props.buttonStyle ].textShadow ) : '-1px 1px 0 rgba(0, 0, 0, 0.18)' };
            box-shadow: ${ props => props.uiScheme ? props.uiScheme.boxShadow( props.uiScheme.buttons[ props.buttonStyle ].boxShadow ) : 'box-shadow: -3px 3px 0 0 #f5F5F5' };

            &:hover {
                background: ${ props => props.uiScheme ? props.uiScheme.buttons[ props.buttonStyle ].hover.background : 'red' };
                color: ${ props => props.uiScheme ? props.uiScheme.buttons[ props.buttonStyle ].hover.text : 'white' };
                border-color: ${ props => props.uiScheme ? props.uiScheme.buttons[ props.buttonStyle ].hover.border : 'white' };
                text-shadow: ${ props => props.uiScheme ? props.uiScheme.textShadow( props.uiScheme.buttons[ props.buttonStyle ].hover.textShadow ) : '-1px 1px 0 rgba(0, 0, 0, 0.18)' };
                box-shadow: ${ props => props.uiScheme ? props.uiScheme.boxShadow( props.uiScheme.buttons[ props.buttonStyle ].hover.boxShadow ) : 'box-shadow: -3px 3px 0 0 #f5F5F5' };
            }

        ` 
        //else
        : css`

            background: ${ props => props.uiScheme ? props.uiScheme.buttons[ props.buttonStyle ].background : 'red' };
            color: ${ props => props.uiScheme ? props.uiScheme.buttons[ props.buttonStyle ].text : 'white' };
            border-color: ${ props => props.uiScheme ? props.uiScheme.buttons[ props.buttonStyle ].border : 'white' };
            text-shadow: ${ props => props.uiScheme ? props.uiScheme.textShadow( props.uiScheme.buttons[ props.buttonStyle ].textShadow ) : '-1px 1px 0 rgba(0, 0, 0, 0.18)' };
            box-shadow: ${ props => props.uiScheme ? props.uiScheme.boxShadow( props.uiScheme.buttons[ props.buttonStyle ].boxShadow ) : 'box-shadow: -3px 3px 0 0 #f5F5F5' };

            &:hover {
                background: ${ props => props.uiScheme ? props.uiScheme.buttons[ props.buttonStyle ].hover.background : 'red' };
                color: ${ props => props.uiScheme ? props.uiScheme.buttons[ props.buttonStyle ].hover.text : 'white' };
                border-color: ${ props => props.uiScheme ? props.uiScheme.buttons[ props.buttonStyle ].hover.border : 'white' };
                text-shadow: ${ props => props.uiScheme ? props.uiScheme.textShadow( props.uiScheme.buttons[ props.buttonStyle ].hover.textShadow ) : '-1px 1px 0 rgba(0, 0, 0, 0.18)' };
                box-shadow: ${ props => props.uiScheme ? props.uiScheme.boxShadow( props.uiScheme.buttons[ props.buttonStyle ].hover.boxShadow ) : 'box-shadow: -3px 3px 0 0 #f5F5F5' };
            }

        `
    
    }


    ${ props => props.buttonStyle === 'tab' && css`

        box-shadow: none;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0%;

        background: ${ props => props.uiScheme ? props.isActive ? props.uiScheme.tabs.active.background : props.uiScheme.tabs.background : 'transparent' };

        

        margin: 0 !important;

        border-width: 1px;
        border-style: solid;
        border-color: ${ props => props.uiScheme ? props.uiScheme.tabs.borderColour : 'box-shadow: -3px 3px 0 0 #f5F5F5' };


        ${ 
        
            props => props.isActive
            ? props.tabStyle === 'top' ? css`
            
                border-bottom-color: transparent;

            `

            : css`
            
                border-top-color: transparent;

            `

            : ''

        }
        

        &:hover {

            box-shadow: none;

        }
        
    `};

`;

const StyledTitle = styled.div`

    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    text-direction: center;
    padding: ${ props => props.uiScheme.padding.buttons };

    ${ 
            
        props => props.isActive 
        ? css`

            background: ${ props => props.uiScheme ? props.isHover ? props.uiScheme.buttons[ props.buttonStyle ].active.title.background : props.uiScheme.buttons[ props.buttonStyle ].active.title.background : props.isHover ? 'white' : 'red' };
            color: ${ props => props.uiScheme ? props.isHover ? props.uiScheme.buttons[ props.buttonStyle ].active.title.text : props.uiScheme.buttons[ props.buttonStyle ].active.title.text : props.isHover ? 'red' : 'white' };

            &:hover {
                
                background: ${ props => props.uiScheme ? props.isHover ? props.uiScheme.buttons[ props.buttonStyle ].active.hover.title.background : props.uiScheme.buttons[ props.buttonStyle ].active.title.background : props.isHover ? 'white' : 'red' };
                color: ${ props => props.uiScheme ? props.isHover ? props.uiScheme.buttons[ props.buttonStyle ].active.hover.title.text : props.uiScheme.buttons[ props.buttonStyle ].active.title.text : props.isHover ? 'red' : 'white' };

            }


        ` 
        //else
        : css`
            background: ${ props => props.uiScheme ? props.isHover ? props.uiScheme.buttons[ props.buttonStyle ].title.background : props.uiScheme.buttons[ props.buttonStyle ].title.background : props.isHover ? 'white' : 'red' };
            color: ${ props => props.uiScheme ? props.isHover ? props.uiScheme.buttons[ props.buttonStyle ].title.text : props.uiScheme.buttons[ props.buttonStyle ].title.text : props.isHover ? 'red' : 'white' };

            &:hover {

                background: ${ props => props.uiScheme ? props.isHover ? props.uiScheme.buttons[ props.buttonStyle ].hover.title.background : props.uiScheme.buttons[ props.buttonStyle ].title.background : props.isHover ? 'white' : 'red' };
                color: ${ props => props.uiScheme ? props.isHover ? props.uiScheme.buttons[ props.buttonStyle ].hover.title.text : props.uiScheme.buttons[ props.buttonStyle ].title.text : props.isHover ? 'red' : 'white' };

            }

        `

    }

`;

const StyledChildren = styled.div`

    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    flex-direction: ${ props => props.orientation ? flexMap[props.orientation] : 'row' };

`;

export default
function Button( props ) {

    // props.isActive

    const [ isHover, setIsHover ] = useState( false );

    function renderChildren() {

        if( props.title ) {

            return(

                <>

                    <StyledTitle

                        isActive={ props.isActive }
                        isHover={ isHover }
                        uiScheme={ props.uiScheme }
                        buttonStyle={ props.buttonStyle }

                    >

                        { props.title }

                    </StyledTitle>

                    <StyledChildren

                        isActive={ props.isActive }
                        isHover={ isHover }
                        uiScheme={ props.uiScheme }
                        buttonStyle={ props.buttonStyle }

                    >

                        { props.children }

                    </StyledChildren>

                </>
            );
        }
        else {

            return(

                <>

                    { props.children }

                </>
            );

        }
    };

    return (
        <>
            <StyledButton

                className={ `${ props.buttonStyle === 'tab' ? 'loss-tab' : 'loss-btn' } ${ props.isActive ? 'loss-active' : '' } ${ props.extraClassName ? props.extraClassName : '' }` }
                forwardedforwardedKey={ props.forwardedKey }
                onMouseEnter={ () => setIsHover( true ) }
                onMouseLeave={ () => setIsHover( false ) }
                onClick={ () => props.onClick() }
                isActive={ props.isActive }
                uiScheme={ props.uiScheme }
                buttonStyle={ props.buttonStyle ? props.buttonStyle : 'default' }
                tabStyle={ props.tabStyle }
                orientation={ props.orientation }
                isHover={ isHover }
                grow={ props.grow }
                
            >

                { renderChildren() }
                    
            </StyledButton>
        </>
    );

}