import React, { useState } from 'react';

import styled from 'styled-components';

import Button from '../buttons/Button';

import AssetMap from '../../assets/asset-import-map';


const StyledImage = styled.img`
    width: 48px;
    height: 48px;
`;

export default
function SelectButtons( props ) {

    const [ isHover, setIsHover ] = useState( false );

    let selectionArray = [...props.selectionMap.keys()];

    return(
        <div
            className={ `loss-button-select ${ props.flexAlignment }` }
            
            onMouseEnter={ () => setIsHover( true ) }
            onMouseLeave={ () => setIsHover( false ) }
        >
            {
                selectionArray.map( ( selection ) => {

                    let selectionObject = props.selectionMap.get( selection );

                    console.log('scrub', selectionObject);

                    return(
                        <Button
                            alignment={ props.buttonAlignment }
                            isActive={ selection === props.curSelection ? true : false }
                            forwardedKey={ selection }
                            onClick={() => props.onAudiogramSelect( selection )} 
                            stylin={ props.btnStylin }
                        >
                            <div className="app-tab-image-container">
                                <StyledImage 
                                    src={ AssetMap[selectionObject.options.img] }
                                    alt={ selectionObject.options.alt }
                                    className="hlt-programme-img"
                                    
                                />

                            </div>
                            <div>{ selectionObject.options.name }</div>
                        </Button>
                    );
                })
            }
        </div>
        
            
    );
}