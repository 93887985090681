import AssetMap from '../../../../assets/asset-import-map';


export const options = new Map();
options.set('normal', { name: 'Normal', userInput: false, help: 'This is the "normal" preset, meaning no hearing loss. Use it to reference the other examples and your own hearing loss.', img: AssetMap['normal_graph'], mono : true, subtitle : ""});
options.set('my-unaided', { name: 'My Hearing Loss (unaided)', userInput: true, help: 'This preset will simulate both the Left and Right ears of your audiogram together.', img: AssetMap['hearing_loss_simulator'], mono : false, subtitle : ""});
options.set('my-aided', { name: 'My Hearing Loss (aided)', userInput: true, help: 'This preset will simulate both the Left and Right ears of your audiogram together.', img: AssetMap['hearing_loss_simulator'], mono : false, subtitle : ""});
options.set('sound-box-1', { name: 'Soundbox 1', userInput: true, help: 'This is a free mode allowing you to experiment with settings. This preset will play both left and right ears together.', img: AssetMap['experimentation'], mono : false, subtitle : ""});
options.set('sound-box-2', { name: 'Soundbox 2', userInput: true, help: 'This is a free mode allowing you to experiment with settings. This preset will play both left and right ears together.', img: AssetMap['experimentation'], mono : false, subtitle : ""});
options.set('sound-box-3', { name: 'Soundbox 3', userInput: true, help: 'This is a free mode allowing you to experiment with settings. This preset will play both left and right ears together.', img: AssetMap['experimentation'], mono : false, subtitle : ""});
options.set('sound-box-4', { name: 'Soundbox 4', userInput: true, help: 'This is a free mode allowing you to experiment with settings. This preset will play both left and right ears together.', img: AssetMap['experimentation'], mono : false, subtitle : ""});
options.set('mild', { name: 'Mild', userInput: false, help: 'This is a mild hearing loss.', img: AssetMap['mild_graph'], mono : true, subtitle : ""});
options.set('moderate', { name: 'Moderate', userInput: false, help: 'This is a slightly more severe hearing loss than the mild.', img: AssetMap['moderate_graph'], mono : true, subtitle : ""});
options.set('rubella', { name: 'Rubella', userInput: false, help: 'This is a slightly less common hearing loss where the low end (low frequency i.e bass sounds, rumble from a stampede) is reduced, rather than the high end (high frequency i.e a whisper or break sqeal).', img: AssetMap['rubella_graph'], mono : false, subtitle : ""});
options.set('measles', { name: 'Measles', userInput: false, help: 'This is Gordon\'s hearing loss. It is a severe loss.', img: AssetMap['severe_graph'], mono : false, subtitle : ""});
options.set('gordon', { name: 'Gordon\'s Loss', userInput: false, help: 'This is Gordon\'s hearing loss. It is a severe loss.', img: AssetMap['severe_graph'], mono : false, subtitle : ""});
options.set('severe', { name: 'Severe Loss', userInput: false, help: 'This is Gordon\'s hearing loss. It is a severe loss.', img: AssetMap['severe_graph'], mono : false, subtitle : ""});
options.set('aided', { name: 'Gordon\'s hearing aid', userInput: false, help: 'This is Gordon\'s hearing loss with the introduction of a hearing aid.', img: AssetMap['hearingaid_graph'], mono : false, subtitle : ""});
options.set('loop', { name: 'Gordon\'s loop system', userInput: false, help: 'This is Gordon\'s hearing loss with the introduction of a loop system and hearing aid.', img: AssetMap['loop_graph'], mono : false, subtitle : ""});

export var programmes = new Map();
// programmes.set(
//     'my-hearing-loss',
//     {
//         options : {
//             title: 'My Hearing Loss',
//             slug : 'my-hearing-loss',
//             audiograms: ['normal', 'my-unaided', 'my-aided'],
//             default: 'normal',
//             img: AssetMap['assetProgrammesMyHearingLoss'],
//             graphDisplay : 'single',
//             graphMono : false
//         }
//     }
// );
programmes.set(
    'examples',
    {
        options : {
            title: 'Hearing Loss Examples',
            subtitle: "",
            slug : 'examples',
            audiograms: ['normal', 'mild', 'moderate', 'rubella', 'measles'],
            default: 'normal',
            img: AssetMap['assetProgrammesExamples'],
            graphDisplay : 'single',
            graphMono : true
        }
    }
);
programmes.set(
    'hearing-aid',
    {
        options : {
            title: 'Hearing Aid Examples',
            subtitle: "",
            slug : 'hearing-aid',
            audiograms: ['normal', 'gordon', 'aided', 'loop'],
            default: 'normal',
            img: AssetMap['programme_hearing_aid'],
            graphDisplay : 'all',
            graphMono : true
        }
    }
);
// programmes.set(
//     'sound-box',
//     {
//         options : {
//             title: 'Soundbox Playground',
//             slug : 'sound-box',
//             audiograms: ['normal','sound-box-1','sound-box-2','sound-box-3','sound-box-4'],
//             default: 'normal',
//             img: AssetMap['assetProgrammesSandbox'],
//             graphDisplay : 'single',
//             graphMono : false
//         }
//     }
// );
