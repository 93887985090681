import { getSeverity } from '../maps/severityMap';
import { colourMap } from '../maps/colourMap';

export
function createGraphData() {

    // TODO - it's very messy and not intelligent

    let displayMode = 'single'; // 'single' or 'all'
    let averageMode = false;
    let graphMono = true;

    let isBuilt = false;

    let graphArray = [];
    let keyArray = [];
    // temporarily an object, to add each point on the xAxis.set below
    // as a member, then iterate to push to the main array on build
    let graphVals = {};

    // to store the array of points along x axis
    // to allow iteration over data sets to add
    // to the graph data array
    
    let xAxis = {
        title : null,
        label : null,
        set : null,
    };

    // stores the app name of the current 
    // data points in focus. i.e "normal"
    // for the current audiogram
    let currentDataSet = null;

    // holds info for current active graph
    // in 'all' mode this will output a changing
    // graph to show movement between the sets
    let active = {
        key : null,
        value : null
    };


    const pointStyles = [
        { pointShape: 'circle' },
        { pointShape: 'triangle' },
        { pointShape: 'square' },
        { pointShape: 'diamond' },
        { pointShape: 'star' },
        { pointShape: 'polygon' },
    ];

    

    const publicAPI = {
        getSet() {

            if( !isBuilt ) {
                this.buildSet();
            }
            return graphArray;
        },

        setDisplayMode( option ) {
            displayMode = option;
        },
        setCurrentDataSet( current ) {
            currentDataSet = current;
        },
        setGraphMono( isMono ) {
            graphMono = isMono;
        },
        clearSet() {
            graphArray = [];
            keyArray = [];
            graphVals = {};
            isBuilt = false;
        },
        buildSet() {
            graphArray.push(keyArray);
            for( let xItem of xAxis.set ) {
                graphArray.push(graphVals[xItem]);
            }
        },
    
        setXAxis( title, label, set  ) {
    
            xAxis.title = title;
            xAxis.label = label;
            xAxis.set = set;

            this.prepareArray();
    
        },

        prepareArray() {

            this.clearSet();

            this.addKey( xAxis.title );
            
            for( const item of xAxis.set ) {
                // new data will need to first create the storage array
                this.newData( item, `${item}${xAxis.label}` );
            }

        },

        addSingle( key, value, isActiveDisplay) {
            const options = value.options;
            const audiogram = value.audiogram;

            if( isActiveDisplay ) {
            }

            let isActive = ( key === currentDataSet && displayMode === 'single' );
            if( isActiveDisplay ) {
                isActive = true;
            }

            if( key === currentDataSet || isActive ) {
                active.key = key;
                active.value = value;
            }

            let LR = ['left'];

            if( !graphMono || displayMode == 'single' ) {
                LR.push( 'right' ); 
            }

            if( displayMode === 'all' ) {
                this.addKey( options.name )
                this.addKey( { "type": "string", "role": "style" } );
                // so we display both points in the set
                
                
            }
            else if ( displayMode === 'single' ) {
                this.addKey( `${options.name} (L)` );
                this.addKey( { "type": "string", "role": "style" } );
                this.addKey( `${options.name} (R)` );
                this.addKey( { "type": "string", "role": "style" } );
            }

            
            


            let count = 0;
            // Push data point(s) and style
            for( let xItem of xAxis.set ) {

                // const dataValue = audiogram[ ear ][Number(xItem)];
                // const severity = getSeverity( dataValue );

                const shapeType = pointStyles[ count % ( pointStyles.length -1 ) ].pointShape;

                // const graphPointStyle = {
                //     left : isActive ?  `point {opacity: 1;" : "opacity: .3; shape-type: ${shapeType}}`,
                //     right : isActive ? `point {opacity: 1;" : "opacity: .3; shape-type: ${shapeType}}`,
                // }

                for( const ear of LR ) {

                    const dataValue = audiogram[ ear ][Number(xItem)];
                    const severity = getSeverity( dataValue );

                    if( isActiveDisplay ) {
                        console.log( 'beans', severity );
                    }
                    const fillColor = isActiveDisplay ? `fill-color : ${ colourMap[ severity ] };` : ``;

                    this.addData( xItem, dataValue );
                    this.addData( xItem, `point {
                        opacity: ${ isActive ? 1 : 0.3 }; 
                        shape-type: ${shapeType};
                        ${ fillColor }
                    }` );
                }

            }
        },

        createSetFromSingle( key, single ) {

            
    
            if( !xAxis.set ) {
                return new Error('no x axis set yet');
            }
            
            this.addSingle( key, single );

            return this.getSet();

        },
    
        createSetFromMap( map ) {
    
            
    
            if( !xAxis.set ) {
                return new Error('no x axis set yet');
            }
            
            map.forEach( (value, key) => {
    
                this.addSingle( key, value, false);
    
            });
            if( displayMode === 'all' ) {
                this.addSingle( 'Active', active.value, true);
            }

            

            return this.getSet();
    
        },
    
        addDataFromSet( key ){
    
        },
        
        newData( key, data ) {
            graphVals[ key ] = [];
            this.addData( key, data );
        },

        addData( key, data ) {
            graphVals[ key ].push( data );
        },
    
        addKey( key ) {
            keyArray.push( key );
        },
    };

    return publicAPI;

}