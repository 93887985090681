import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { flexMap } from '../../maps/flexMap';

const paneSpacer = '1vh';

const StyledPaneContainer = styled.div`

    padding: ${ paneSpacer };
    margin: ${ paneSpacer };

    /* account below for the margin */
    height: ${ props => props.paneHeight ? `calc( ${ props.paneHeight } - ( ${ paneSpacer } * 2 ) )` : '100%' };

    ${ 
        
        props => props.paneWidth ? 
        props.paneWidth === 'auto' ? css`
            width: auto;
        `
        : css`

            width: ${ props => props.paneWidth ? `calc( ${ props.paneWidth } - ( ${ paneSpacer } * 2 ) )` : 'unset' };

        `
        : ''

    }

    display: flex;
    flex-direction: ${ props => props.orientation ? flexMap[ props.orientation ] : 'row' };
    justify-content: space-between;


`;

export default
function PaneContainer( props ) {

    return(
        <StyledPaneContainer
            forwardedKey={ props.forwardedKey }
            ref={ props.forwardedRef }
            orientation={ props.orientation } 
            paneHeight={ props.paneHeight }
            paneWidth={ props.paneWidth }
            className={ `loss-pane-container ${ props.extraClassName }` }
            // onMouseEnter={ () => setIsHover( true ) }
            // onMouseLeave={ () => setIsHover( false ) }
        >

            { props.children }

        </StyledPaneContainer>
    );
}