import { uiScheme } from './schemes/uiSchemeTemplate';
import { draculaBuffy } from './schemes/draculaBuffy';

export default
function useUiSchemes( scheme ) {

    const schemes = {

        default : uiScheme,
        draculaBuffy : draculaBuffy,

    }

    return schemes[ scheme ];

}


