import { create as createAEModule } from '../constructors/base/base-audio-mod';
import { create as createBufferLoader } from '../helpers/bufferLoader';

export
async function create(audioEngine, newConfig) {

    //TODO make better
    const losseiverwp = window.losseiverwp;


    var config = {
        chainBuilt : false,
        isSoloed : false
    };

	// holds the underlying module code
    var AEModule = createAEModule(audioEngine);
    AEModule.mergeConfig(config);

    var publicAPI = Object(AEModule);

    // publicAPI.bypass(true);

    // eq setup, will have buffer prop when fetch resolved

    // create userInput

    
    try {
        const userMedia = await navigator.mediaDevices.getUserMedia({ audio : true, video : false });

        console.log( 'publiuc', userMedia );

        const audioContext = audioEngine.getAudioContext();

        const userInput = audioContext.createMediaStreamSource( userMedia );

        audioContext.connectDestination( userInput );

        publicAPI.userInput = userInput;

    }
    catch( err ) {

        console.log( 'publiuc',err );

    }
    


	
	return publicAPI;
}



