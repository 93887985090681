import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import * as WebflowReact from './WebflowReact';

import './loss-new/css/loss.css';



import Demo from './losseiver/Demo';

// Very hacky and "unsafe" when no Wordpress install present
if (!window.losseiverwp) {
    const reactUrl = `${window.location.protocol}//${window.location.hostname}${ window.location.port ? `:${window.location.port}` : ''}/`;
    console.log( 'url', reactUrl);
    window.losseiverwp = {
        'wp-nonce': 'beans',
        "site-url": "https://new.losseiver.co.uk",
        // "react-url": "https://new.losseiver.co.uk/"
        "react-url": reactUrl
    }
}

ReactDOM.render(
  <React.StrictMode>
    <Demo 
        portals={ WebflowReact.portals }
        refs={ WebflowReact.refs }
    />
  </React.StrictMode>,
  document.getElementById('root')
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
