import React from "react";
import Chart from "react-google-charts";

import * as HLTLabels from '../../language/HLTLabels';


export default
function GraphHLS(props) {

    const showSliders = props.showSliders;

    const currentAudiogram = props.currentAudiogram;

    
    
    const options = {
        // title: currentAudiogram,
        // curveType: "function",
        backgroundColor: { fill:'transparent' },
        hAxis : {
            title : `Frequency (${HLTLabels.hz})`
        },
        pointSize : 10,
        pointSize: 10,
        chartArea : {
            top : 20,
        },
        animation:{
            duration: 500,
            startup: true,
            easing: 'linear',
        },
        vAxis: {
            viewWindow: {
              max: -130,
              min: 10,
            },
          },
          legend: { position: 'bottom' },
          titleTextStyle: {
            textAlign: 'center'
        }, 
        vAxis : {
            title : `Hearing Loss (${HLTLabels.dbhl})`,
            ticks : [
                {v: 10,f:`-10${HLTLabels.dbhl}`},
                {v: 0,f:`(normal) 0${HLTLabels.dbhl}`},
                 {v:-10,f:`10${HLTLabels.dbhl}`},
                 //{v:-20,f:`20${HLTLabels.dbhl}`},
                 {v:-30,f:`30${HLTLabels.dbhl}`},
                 //{v:-40,f:`40${HLTLabels.dbhl}`},
                 {v:-50,f:`50${HLTLabels.dbhl}`},
                 //{v:-60,f:`60${HLTLabels.dbhl}`},
                 {v:-70,f:`70${HLTLabels.dbhl}`},
                 //{v:-80,f:`80${HLTLabels.dbhl}`},
                 {v:-90,f:`90${HLTLabels.dbhl}`},
                 //{v:-100,f:`100${HLTLabels.dbhl}`},
                 {v:-110,f:`110${HLTLabels.dbhl}`},
                 //{v:-120,f:`120${HLTLabels.dbhl}`},
                 {v:-130,f:`130${HLTLabels.dbhl}`},
                ]
        }
    };

    return (
        <div id="hlt-graph" className="loss-graph-container" style={{ padding: '10px'}}>   
            <Chart
                ref={ props.graphRef }
                chartType="LineChart"
                width="100%"
                height={showSliders ? '80%' : '100%'}
                data={props.data}
                options={options}
                min="10"
                max="-130"
                />
        </div>
    );
    
}