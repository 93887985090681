import { create as newBASE } from './base.js';

export 
function create(audioEngine) {

    // default options
    var config = {
        base : {},
        audio : {},
        isAudioNode : true
    };
    
    // instatiate base js module and 
    // combine this modules default configs
    // with user passed e.g from module above
    var BASE = newBASE();
    BASE.mergeConfig(config);

    // Inherit methods
    var publicAPI = Object.create(BASE);

	publicAPI.audioContext = audioEngine.getAudioContext();
	publicAPI.nodes = {};
	publicAPI.states = {
		isBypassed : false,
	};

	publicAPI.createIO = function createIO() {

        
		this.nodes.in = this.createGain(1);
		this.nodes.out = this.createGain(1);

		

		// setup bypass chain
        this.nodes.inBypass 	= this.createGain(Number(this.states.isBypassed)); 
		this.nodes.inWet 	= this.createGain(this.states.isBypassed ? 0 : 1);

		this.nodes.in.connect(this.nodes.inBypass);
        this.nodes.in.connect(this.nodes.inWet);
        
        // N.B Wet is where the modules will pickup from
        // and add specific chain and connect to out

		this.nodes.inBypass.connect(this.nodes.out);

		
	}

	publicAPI.createGain = function createGain(gain, name = 'gainNode') {
        let gainNode = this.audioContext.createGain();
        gainNode.name = name;
		this.setGain(gainNode, gain);
		return gainNode;
	}

	publicAPI.setGain = function setGain(gainNode, gain, time = this.audioContext.currentTime) {
		return gainNode.gain.setValueAtTime(gain, time);
    }
    
    publicAPI.createConvolver = function createConvolver(buffer, name = 'convolverNode') {
        let convolver = this.audioContext.createConvolver();
        convolver.name = name;
        convolver.buffer = buffer;
        return convolver;
    }

    publicAPI.createChannelSplitter = function createChannelSplitter(noChan, name = "channelSplitter") {
        let channelSplitter = this.audioContext.createChannelSplitter(noChan);
        channelSplitter.name = name;
        return channelSplitter;
    }

    publicAPI.createChannelMerger = function createChannelMerger(noChan, name = "channelMerger") {
        let channelMerger = this.audioContext.createChannelMerger(noChan)
        return channelMerger;
    }

	publicAPI.bypass = function bypass(bypass) {
		this.states.isBypassed = bypass;
		this.setGain(this.nodes.inBypass, Number(bypass));
        this.setGain(this.nodes.inWet, bypass ? 0 : 1);
        return bypass;
	}


	// connect this node into the chain
	// if there are prev or next this.nodes
	// otherwise just setup internal IO
	publicAPI.createIO();






    
    

    // merge configs

    // Add methods
    publicAPI.attachInput = function attach(node) {
        //TODO up security here
        // input.constructor.name === "GainNode"
        // something like that
        node.connect(this.nodes.in);
    };
    publicAPI.connect = function connect(node) {
        //TODO up security here
        // input.constructor.name === "GainNode"
        // something like that
        this.nodes.out.connect(node);
    };
    
    publicAPI.getNode = function getNode(nodeName) {
        // TODO security here
        return this.nodes[nodeName];
    };

	
       
	return publicAPI;
}