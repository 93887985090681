import React from 'react';

import Button from '../../../../losseiver/components/buttons/Button';

import { colourMap } from '../../../../losseiver/maps/colourMap';

import ButtonIcon from '../../../../losseiver/components/buttons/ButtonIcon';
import ButtonGroup from '../../../../losseiver/components/buttons/ButtonGroup';


const SelectButton = (props) => {

    return (

        <button
            onClick={props.onClick}
            className={`loss-player-audio-select-link ${props.isActive ? 'active' : ''} w-inline-block `}
        >
            <div className={`audio-select-icon-container ${props.type}`}>
                <div className="lindua icon-player-audio-select-link"> {props.icon} </div>
            </div>
            <div>
                <div className="menu-title">{props.title}</div>
                <div className="menu-subtext">{props.subtitle}</div>
            </div>
        </button>

    );

};


export default
    function ChoiceGroup(props) {

    const title = props.title;
    const choiceMap = props.choiceMap;
    const choiceKeys = [];
    const currentChoice = props.currentChoice;

    props.choiceMap.forEach((item, i) => choiceKeys.push(i));


    const close = () => {
        props.close();
    };

    function onChoiceSelect(selection) {
        props.onChoiceSelect(selection);
        close();
    }

    // function renderButton() {

    //     return(

    //     );

    // }

    return (
        <>
            {
                choiceKeys.map((choice) => {
                    const isActive = currentChoice === choice;
                    const choiceObj = choiceMap.get(choice).options;
                    const colourObj = isActive ? colourMap.selected : colourMap.unselected;
                    return (


                        <SelectButton
                            key={choiceObj.slug}
                            title={choiceObj.title || choiceObj.name}
                            subtitle={choiceObj.subtitle}
                            isActive={isActive}
                            icon={choiceObj.icon}
                            onClick={() => onChoiceSelect(choiceObj)}
                        >
                        </SelectButton>





                    );
                })
            }

            <div
                className={`loss-player-audio-select-link w-inline-block `}
            >
                <div className={`audio-select-icon-container`}>
                    <div className="lindua icon-player-audio-select-link"> cassette </div>
                </div>
                <div>
                    <div className="menu-title">Upload your audio</div>
                    <div className="menu-subtext">Coming Soon</div>
                </div>
            </div>

            <div
                className={`loss-player-audio-select-link w-inline-block `}
            >
                <div className={`audio-select-icon-container`}>
                    <div className="lindua icon-player-audio-select-link"> antenna </div>
                </div>
                <div>
                    <div className="menu-title">Use an audio input</div>
                    <div className="menu-subtext">Coming Soon</div>
                </div>
            </div>

        </>
    );
}
