import React from 'react';

const SelectButton = (props) => {

    return (

        <button 
            onClick={ props.onClick }
            className={`loss-select-nav-link ${props.type} ${ props.isActive ? 'active' : ''} w-inline-block `}
        >
            <div className={`audio-select-icon-container ${props.type}`}>
                <img src={ props.img } className={ "loss-btn-img" } alt="" />
            </div>
            <div>
                <div className="menu-title">{props.title}</div>
                <div className="menu-subtext">{props.subtitle}</div>
            </div>
        </button>

    );

};


export default
    function SelectImageButtonGroupFromMap(props) {

    const choiceMap = props.choiceMap;
    const choiceKeys = [];
    const currentChoice = props.currentChoice;

    props.choiceMap.forEach((item, i) => choiceKeys.push(i));

    function onChoiceSelect(selection) {
        props.onChoiceSelect(selection);
    }

    return (

        <>
            {
                choiceKeys.map((choice) => {
                    const isActive = currentChoice === choice;
                    const choiceObj = choiceMap.get(choice).options;

                    return (


                            <SelectButton
                                key={choiceObj.slug}
                                title={choiceObj.title || choiceObj.name}
                                subtitle={choiceObj.subtitle}
                                type={props.type}
                                isActive={isActive}
                                img={choiceObj.img}
                                onClick={ () => onChoiceSelect( choice ) }
                            >
                            </SelectButton>


                    );
                })
            }
        </>

    );
}
