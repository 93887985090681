const util = require('util');



export
function create(audioEngine) {

    let callbackDecode = null;

    


    function getData(url) {
      return fetch(url)
      .then(function(response) {
        if (!response.ok) {
          throw new Error("HTTP error, status = " + response.status);
        }
        return response.arrayBuffer();
      })
    };

    function decodeData(data) {
        let audioContext = audioEngine.getAudioContext();
        // console.log('ben', audioEngine.getAudioContext().decodeAudioData(data));
        // decode data returns a promise

        
        if(audioEngine.promiseEnabled) {
            return audioContext.decodeAudioData(data);
        }

        // if running old audio context
        return new Promise( function (resolve, reject) {
            audioContext.decodeAudioData(data, resolve, reject);
        });
        
        
        
    }

    var publicAPI = {
        async loadBuffers(urls){
            var bufferList = [];


            return new Promise( (resolve, reject) => {
                urls.forEach( url => {
                    this.decodeBuffer(url)
                    .then(buffer => {
                        bufferList.push(buffer);
                    })
                    .catch( err => {
                        //TODO gracefully handle a buffer error here
                        // i.e other buffers might be fine and could the
                        // app handle it?
                        reject(err);
                    })
                });
                resolve(bufferList);
            });
        },
        async loadBuffer(url) {


            // if(audioEngine.promiseEnabled) {
                return new Promise( (resolve, reject) => {
                
                    getData(url)
                    .then( buffer => {
                        return decodeData(buffer);
                        
                    })
                    .then( buffer => {
                        resolve( buffer );
                    })
                    .catch( err => {
                        //TODO gracefully handle a buffer error here
                        // i.e other buffers might be fine and could the
                        // app handle it?
                        reject(err);
                    })
                });
            // }
            // else {
            //     const theData = await getData(url);
            //     console.log('ben', theData);
            // }

             

            
        }
    }
    return publicAPI;
}

