import React, { useState } from 'react';
import styled from 'styled-components';

const StyledIcon = styled.div`
    font-size : ${ props => props.size ? props.size : '24px' };
    line-height : ${ props => props.size ? props.size : '24px' };
`;

export default
function icon( props ) {

    return(
        
        <StyledIcon
            size={ props.size }
            className="icomoon"
        >
            { props.children }

        </StyledIcon>
    );
}