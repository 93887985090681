export function getAudiogram( name ) {

    if( aliasMap[ name ] ) {
        name = aliasMap[ name ];
    }
    return audiograms.get( name );
}

const aliasMap = {
    'gordon' : 'severe',
    'measles' : 'severe',
}
const audiograms = new Map();

audiograms.set('default', {
    left : {
        125  : 0,
        250  : 0,
        500  : 0,
        1000 : 0,
        2000 : 0,
        4000 : 0,
        8000 : 0,
    },
    right : {
        125  : 0,
        250  : 0,
        500  : 0,
        1000 : 0,
        2000 : 0,
        4000 : 0,
        8000 : 0,
    }
}
);
audiograms.set('normal', {
    left : {
        125  : 0,
        250  : 0,
        500  : 0,
        1000 : 0,
        2000 : 0,
        4000 : 0,
        8000 : 0,
    },
    right : {
        125  : 0,
        250  : 0,
        500  : 0,
        1000 : 0,
        2000 : 0,
        4000 : 0,
        8000 : 0,
    }
}
);
audiograms.set('rubella', {
    left : {
        125  : 0,
        250  : -45,
        500  : -50,
        1000 : -55,
        2000 : -45,
        4000 : 0,
        8000 : -15,
    },
    right : {
        125  : 0,
        250  : -45,
        500  : -50,
        1000 : -55,
        2000 : -45,
        4000 : 0,
        8000 : -15,
    }
}
);
audiograms.set('mild', {
    left : {
        125  : 0,
        250  : -15,
        500  : -25,
        1000 : -30,
        2000 : -30,
        4000 : -50,
        8000 : -65,
    },
    right : {
        125  : 0,
        250  : -15,
        500  : -25,
        1000 : -30,
        2000 : -30,
        4000 : -50,
        8000 : -65,
    }
}
);
audiograms.set('moderate', {
    left : {
        125  : 0,
        250  : -40,
        500  : -50,
        1000 : -55,
        2000 : -55,
        4000 : -60,
        8000 : -65,
    },
    right : {
        125  : 0,
        250  : -40,
        500  : -50,
        1000 : -55,
        2000 : -55,
        4000 : -60,
        8000 : -65,
    }
}
);
audiograms.set('severe', {
    left : {
        125  : 0,
        250  : -60,
        500  : -80,
        1000 : -90,
        2000 : -90,
        4000 : -100,
        8000 : -90,
    },
    right : {
        125  : 0,
        250  : -60,
        500  : -70,
        1000 : -90,
        2000 : -90,
        4000 : -100,
        8000 : -90,
    }
}
);
audiograms.set('aided', {
    left : {
        125  : 0,
        250  : -40,
        500  : -45,
        1000 : -40,
        2000 : -40,
        4000 : -60,
        8000 : -60,
    },
    right : {
        125  : 0,
        250  : -45,
        500  : -45,
        1000 : -40,
        2000 : -40,
        4000 : -50,
        8000 : -45,
    }
}
);
audiograms.set('loop', {
    left : {
        125  : 0,
        250  : -20,
        500  : -20,
        1000 : -25,
        2000 : -30,
        4000 : -40,
        8000 : -60,
    },
    right : {
        125  : 0,
        250  : -20,
        500  : -20,
        1000 : -25,
        2000 : -30,
        4000 : -40,
        8000 : -60,
    }
}
);

export default audiograms;

