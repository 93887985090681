//THis is old button


import React, { useState } from 'react';
import styled, { css } from 'styled-components';

const StyledButton = styled.button``;
const StyledInner = styled.div`
    ${ props => props.stylin }
`;

export default
function Button( props ) {

    // props.isActive

    const [ isHover, setIsHover ] = useState( false );

    return (
        <>
            <StyledButton
                className={ `loss-btn ${ props.extraClassName }` }
                id={ props.id }
                onMouseEnter={ () => setIsHover( true ) }
                onMouseLeave={ () => setIsHover( false ) }
                onClick={ () => props.onClick() }
                
            >
                <StyledInner
                    className={ `loss-btn-inner ${ props.isActive ? 'loss-active' : '' } ${ props.alignment} ${ props.innerClassName ? props.innerClassName : '' }` }
                    stylin={ props.stylin }
                >
                    { props.children }
                </StyledInner>
            </StyledButton>
        </>
    );

}