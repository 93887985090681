export
function getSeverity( value ){

    // TODO make sure negative etc. handled correctly

    const thresholds = {
        mild : -10,
        moderate : -20,
        high : -50,
        severe : -60
    }

    let severity = 'normal';

    if( value < thresholds.mild && value > thresholds.moderate ) {
        severity = 'mild';
    }
    if( value < thresholds.moderate && value > thresholds.high ) {
        severity = 'moderate';
    }
    if( value < thresholds.high && value > thresholds.severe) {
        severity = 'high';
    }
    if( value < thresholds.severe) {
        severity = 'severe';
    }

    return severity;
}