import React, { useState, useRef, useEffect } from 'react';

import path from 'path';

const audioSourceMap = new Map();
audioSourceMap.set('music', { options: { title: 'Music', fileName: 'music', slug: 'music', icon: 'music-note' } });
audioSourceMap.set('female', { options: { title: 'Female Voice', fileName: 'female', slug: 'female', icon: 'female' } });
audioSourceMap.set('male', { options: { title: 'Male Voice', fileName: 'male', slug: 'male', icon: 'male' } });


const splashPlayButtons = document.querySelectorAll('.btn-splash-play');

export default
    function IntroAudioPlayer(props) {

    const losseiverwp = window.losseiverwp;

    const defaultSelection = 'male';

    let [isPlaying, setIsPlaying] = useState(false);
    let [canPlay, setCanPlay] = useState(false);

    const audioContext = props.audioEngine.getAudioContext();
    const [curObject, setCurObject] = useState(audioSourceMap.get(defaultSelection).options);
    const audioRef = useRef(null);

    const [loop, setLoop] = useState(false);

    const sourceSet = useRef( null );

    const mediaElemSource = useRef( null );



    const handlePlayClick = () => {

        togglePlay();

    }

    useEffect(() => {
        if (audioRef.current.id && !sourceSet.current) {
            mediaElemSource.current = audioContext.createMediaElementSource(audioRef.current);
            props.setMediaSource( mediaElemSource.current );
            sourceSet.current = true;
        }

            
    }, [audioRef, sourceSet, mediaElemSource]);

    useEffect(() => {

        if ( props.splashClosed ) {
            mediaElemSource.current.disconnect();
        }

            
    }, [ props.splashClosed, mediaElemSource ]);

    useEffect(() => {

        // On Object Change

        if (curObject && audioRef.current) {

            audioRef.current.src = getSrc(curObject.slug);

            if (isPlaying) {
                restart();
            }
            else {
                stop();
            }

        }

    }, [curObject, audioRef])

    useEffect(() => {

        for (const splashPlay of splashPlayButtons) {

            splashPlay.addEventListener('click', handlePlayClick);

            const icon = splashPlay.querySelector('.lindua');
            const text = splashPlay.querySelector('div');

            if (isPlaying) {
                icon.innerHTML = 'pause';
                text.innerHTML = 'Pause';
                splashPlay.classList.add('playing');
            }
            else if (!isPlaying) {
                icon.innerHTML = 'play';
                text.innerHTML = 'Play';
                splashPlay.classList.remove('playing');
            }

        }

        return () => {

            for (const splashPlay of splashPlayButtons) {

                splashPlay.removeEventListener('click', handlePlayClick);

            }
        }

    }, [isPlaying]);






    const restart = function restart() {
        audioRef.current.load();

        resume();
    }

    const resume = function resume() {
        audioRef.current.play();
    }

    const pause = function pause() {
        audioRef.current.pause();
    }

    const stop = function stop() {
        audioRef.current.load();
    }

    function togglePlay() {

        isPlaying ? pause() : resume();
    }

    function getSrc(fileName) {

        const filePath = `${losseiverwp['react-url']}${path.join('audio/', `${fileName}.wav`)}`;
        return filePath;
    }

    function changeSourceObject(obj) {
        if (obj) {
            pause();
            setCurObject(obj);
        }
    }









    return (
        <>
            <audio
                id="introPlayer"
                onEnded={stop}
                src={'/audio/male.wav'}
                onPlaying={(e) => { setIsPlaying(true); }}
                onCanPlay={(e) => { setCanPlay(true); }}
                onPause={(e) => { setIsPlaying(false); }}
                onLoadStart={(e) => { setCanPlay(false); }}
                ref={audioRef}
                loop={loop}
            ></audio>
        </>
    );

}