export
const colourMap = {

    black : '#333',

    button : {
        active : {
            border : '#ff0000'
        }
    },

    selected : {
        background : '#3C485E',
        hover : '#20365E', 
        contrast : '#fff'
    },

    unselected : {
        background : '#F5F5F5',
        hover : '#E7E7E7',
        contrast : '#333'
    },

    play : {
        main : '#47b157',
        border : '#47b157', 
        hover : '#4ec24e',
        text : '#fff',
        shadow : "#1e7847",
        active : {
            border : '#ff0000'
        }
    },
    restart : {
        main : '#f5f5f5',
        border : "#f5f5f5",
        text : "#333333",
        hover : "#ee6961",
        shadow : "#b9b8b8",
    },
    pause : {
        main : '#ffb347',
        border : '#ffb347',
        hover : '#eeb347',
        text : '#fff',
        shadow : "#eb9d2f"
    },
    repeat : {
        main : '#ffb347',
        hover : '#eeb347',
        text : '#fff',
        shadow : "#631427",
        alt : {

        }
    },

    normal : 'green',
    mild: 'blue',
    moderate: 'blue',
    high     : 'red',
    severe : 'black'
    

}