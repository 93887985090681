import React, { useState, useRef, useEffect } from 'react';

import path from 'path';

import ButtonGroup from '../../buttons/ButtonGroup';

import PlayButton from './Buttons/PlayButton';
import RepeatButton from './Buttons/RepeatButton';
import RestartButton from './Buttons/RestartButton';
import AudioSourceButton from './Buttons/AudioSourceButton';

import ChoiceGroup from '../../../../loss-new/components/audio/AudioPlayer/ChoiceGroup';

import PortalFromDOM from '../../../../loss-new/components/portals/PortalFromDOM';

const audioSourceMap = new Map();
audioSourceMap.set('music', { options: { title: 'Music', fileName: 'music', slug: 'music', icon: 'music-note' } });
audioSourceMap.set('female', { options: { title: 'Female Voice', fileName: 'female', slug: 'female', icon: 'female' } });
audioSourceMap.set('male', { options: { title: 'Male Voice', fileName: 'male', slug: 'male', icon: 'male' } });


const AudioSettingButtonRepeat = (props) => {

    return (

        <button
            key={props.forwardedKey}
            onClick={props.onClick}
            className={`loss-player-settings-link w-inline-block `}
        >
            <div className={`audio-select-icon-container ${props.type}`}>
                <div className="lindua icon-player-audio-select-link"> { props.isLooping ? 'repeat-infinite' : 'repeat-once' } </div>
            </div>
            <div>
                <div className="menu-title">{ props.isLooping ? "Audio is on loop" : "Audio plays once" }</div>
                <div className="menu-subtext">{props.subtitle}</div>
            </div>
        </button>

    );

};
const AudioSettingButtonRestart = (props) => {

    return (

        <button
            key={props.forwardedKey}
            onClick={props.onClick}
            className={`loss-player-settings-link w-inline-block `}
        >
            <div className={`audio-select-icon-container`}>
                <div className="lindua icon-player-audio-select-link"> { props.isLooping ? 'repeat-infinite' : 'repeat-once' } </div>
            </div>
            <div>
                <div className="menu-title">{ "Restart" }</div>
                <div className="menu-subtext">{props.subtitle}</div>
            </div>
        </button>

    );

};


export default
    function AudioPlayer(props) {

    const losseiverwp = window.losseiverwp;



    const defaultSelection = 'music';

    const audioContext = props.audioEngine.getAudioContext();
    const [curObject, setCurObject] = useState(audioSourceMap.get(defaultSelection).options);
    const audioRef = useRef(null);

    let [isPlaying, setIsPlaying] = useState(false);
    let [canPlay, setCanPlay] = useState(false);


    const [loop, setLoop] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    const spaceBar = props.spaceBar;

    const sourceSet = useRef( null );


    //*******************************
    // UI Update Refs
    //*******************************

    const sourceRef = props.refs.get( 'loss-ref-player-select' );

    useEffect(() => {

        const sourceTitle = sourceRef.querySelector( '.loss-player-audio-select-title' );
        const sourceIcon = sourceRef.querySelector( '.lindua' );

        sourceTitle.innerHTML = curObject.title;
        sourceIcon.innerHTML = curObject.icon;

    }, [ curObject ]);

    //*******************************
    // //END UI Update Refs
    //*******************************


    //*******************************
    // Button Refs
    //*******************************
    const playBtn = props.refs.get('loss-ref-player-btn');

    const handlePlayClick = () => {

            togglePlay();

    }

    

    useEffect(() => {

        playBtn.addEventListener('click', handlePlayClick);

        return () => {

            playBtn.removeEventListener( 'click', handlePlayClick )
        }

    }, [ isPlaying ]);

    useEffect(() => {

        const icon = playBtn.querySelector( '.lindua' );
        const text = playBtn.querySelector( '.loss-player-btn-txt');

        if ( isPlaying ) {
            icon.innerHTML = 'pause';
            text.innerHTML = 'Pause';
            playBtn.classList.remove( 'btn-play' );
            playBtn.classList.add( 'btn-pause' );
        }
        else if ( !isPlaying ) {
            icon.innerHTML = 'play';
            text.innerHTML = 'Play';
            playBtn.classList.remove( 'btn-pause' );
            playBtn.classList.add( 'btn-play' );
        }

    }, [ isPlaying ]);

    //*******************************
    // //END Button Refs
    //*******************************



    useEffect(() => {
        if (audioRef.current.id && !sourceSet.current) {
            props.setMediaSource(audioContext.createMediaElementSource(audioRef.current));
            sourceSet.current = true;
        }
    }, [audioRef, sourceSet]);

    useEffect(() => {

        // On Object Change

        if (curObject && audioRef.current) {

            audioRef.current.src = getSrc(curObject.slug);

            if (isPlaying) {
                restart();
            }
            else {
                stop();
            }

        }

    }, [curObject, audioRef])

    useEffect(() => {
        if (spaceBar) {
            togglePlay();
        }
    }, [spaceBar])

    const restart = function restart() {
        audioRef.current.load();

        resume();
    }

    const resume = function resume() {
        audioRef.current.play();
    }

    const pause = function pause() {
        audioRef.current.pause();
    }

    const stop = function stop() {
        audioRef.current.load();
    }


    function getSrc(fileName) {

        const filePath = `${losseiverwp['react-url']}${path.join('audio/', `${fileName}.wav`)}`;
        return filePath;
    }

    function changeSourceObject(obj) {
        if (obj) {
            pause();
            setCurObject(obj);
        }
    }

    function togglePlay() {
        
        isPlaying ? pause() : resume();
    }


    return (
        <>

            <PortalFromDOM
                portalDOM={ props.portals.get( "loss-portal-player-settings-nav" ) }
            >
                <AudioSettingButtonRepeat
                onClick={() => { loop ? setLoop(false) : setLoop(true) }}
                isLooping={loop}
                uiScheme={props.uiScheme}
                >

                </AudioSettingButtonRepeat>

                <AudioSettingButtonRestart
                    onClick={restart}
                    uiScheme={props.uiScheme}

                >

                </AudioSettingButtonRestart>


            </PortalFromDOM>

            <PortalFromDOM
                portalDOM={ props.portals.get( "loss-portal-player-select" ) }
            >
                <ChoiceGroup
                    title="Select the audio source"
                    choiceMap={audioSourceMap}
                    currentChoice={curObject.slug}
                    onChoiceSelect={(choice) => changeSourceObject(choice)}
                    isOpen={isOpen}
                    uiScheme={props.uiScheme}
                    close={() => setIsOpen(false)}
                    orientation="vertical"
                >

                </ChoiceGroup>
            </PortalFromDOM>


            






            <div className="hlt-player">
                <audio
                    id="daPlayer"
                    onEnded={stop}
                    src={'/audio/music.wav'}
                    onPlaying={(e) => { setIsPlaying(true); }}
                    onCanPlay={(e) => { setCanPlay(true); }}
                    onPause={(e) => { setIsPlaying(false); }}
                    onLoadStart={(e) => { setCanPlay(false); }}
                    ref={audioRef}
                    loop={loop}
                ></audio>
            </div>
        </>
    );

}