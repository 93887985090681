/*
 
   _                       _                
  | |    ___  ___ ___  ___(_)_   _____ _ __ 
  | |   / _ \/ __/ __|/ _ \ \ \ / / _ \ '__|
  | |__| (_) \__ \__ \  __/ |\ V /  __/ |   
  |_____\___/|___/___/\___|_| \_/ \___|_|   
  
    
*/


/*=============================================
=            Introduction                     =
=============================================*/



/*=====  End of Introduction  ======*/



/*=============================================
=            App Design                       =
=============================================*/


/*----------  Maps  ----------*/

/*=====  End of App Design  ======*/



import React, { useState, useEffect, useRef } from 'react';

import { createGlobalStyle, css } from 'styled-components';

import { Router, Link, useNavigate, useLocation } from '@reach/router';

// import 'normalize.css';
// import './webflow/losseiver.webflow/css/normalize.css';

// import './webflow/losseiver.webflow/css/webflow.css';
// import './webflow/loss-fin.webflow/css/loss-fin.webflow.css';





// import './css/losseiver.css';


//Maps
import AudioMap from './audio/audio-import-map';
import AssetMap from './assets/asset-import-map';

//Hooks
import useUser from './hooks/useUser';
import useUiScheme from './ui/useUiScheme';

// Main App - Routes
import Dashboard from './app/Dashboard';
import Intro from './app/Pages/Intro';
import IntroHome from './app/Pages/Intro/pages/IntroHome';
import IntroSound from './app/Pages/Intro/pages/IntroSound';
import Toolbox from './app/Toolbox/Toolbox';
import HearingLossSimulator from './app/Toolbox/Tools/HearingLossSimulator/HearingLossSimulator';


/*----------  Components  ----------*/
import MainMenu from './components/modals/MainMenu';
import Modal from './components/modals/Modal';

import Pane from './components/panes/Pane';
import ButtonImage from './components/buttons/ButtonImage';
import ButtonIcon from './components/buttons/ButtonIcon';

// import Splash from '../loss-new/components/modals/Splash';
import IntroAudioPlayer from '../loss-new/components/audio/AudioPlayer/IntroAudioPlayer';


/*=============================================
=            UI Schemes            =
=============================================*/

import { draculaBuffy } from './ui/schemes/draculaBuffy';

const GlobalStyles = createGlobalStyle`

    // ${props => props.uiScheme && css`

    //     html {
    //         ${props.uiScheme.variables}
    //     }

    //     body {
    //         background: ${props.uiScheme.background};
    //         color: ${props.uiScheme.text};
    //     }

    //     ::selection {
    //         background: ${props.uiScheme.selection.background};
    //     }

    //     .loss-outer {
    //         border-color: hsl(330, 100%, 75%);
    //     }


    // ` }

`;

/*=====  End of UI Schemes  ======*/






function Demo( props ) {

    //*******************************
    // App Wide Props
    //*******************************
    const [audioEngine, setAudioEngine] = useState(null);
    const user = useUser();
    const uiScheme = useUiScheme('default');

    //*******************************
    // Local
    //*******************************
    const [display, setDisplay] = useState('loading');
    const [mainMenuOpen, setMainMenuOpen] = useState(false);
    const [audioResumed, setAudioResumed] = useState(false);
    //*******************************
    // DOM Refs
    //*******************************
    const appUtility = useRef(null);
    const appFooter = useRef(null);

    //*******************************
    // Portals
    //*******************************
    const [pageSidebar, setPageSidebar] = useState(null);

    //*******************************
    // Splash Screen
    //*******************************
    const [ splashClosed, setSplashClosed ] = useState( false );
    const splash = props.refs.get('loss-ref-splash');
    const closeSplash = props.refs.get('loss-ref-splash-btn');
    const audioSplash = props.refs.get('loss-ref-splash-audio');

    const closeSplashEvent = () => {
        audioEngine.resume();
        setAudioResumed(true);
        setSplashClosed( true );
        splash.remove();
    };
    const resumeAudioEvent = () => {
        audioEngine.resume();
        setAudioResumed(true);
    };

    

    useEffect(() => {

        closeSplash.addEventListener('click', closeSplashEvent);
        audioSplash.addEventListener('click', resumeAudioEvent);

        return () => {
            closeSplash.removeEventListener('click', closeSplashEvent);
            audioSplash.removeEventListener('click', resumeAudioEvent);
        };

    }, [ audioEngine ]);

    //*******************************
    // App Effects
    //*******************************
    useEffect(() => {

        // Try Audio Engine first
        try {
            setAudioEngine(
                //get constructor function and immediately run
                AudioMap.get('audioEngine')()
            );
        }
        catch (err) {
            console.log( 'pilchard', err);
            setDisplay('audio-engine-failed');
        }

        // End if not a user
        if (!user.isUser) {
            setDisplay('user-failed');
        }

    }, [user.isUser]);

    // useEffect(() => {

    //     if( audioEngine ) {

    //         setDisplay( 'splash' );

    //     }

    // }, [ audioEngine ]);

    // Trigger audio stuff as we now have a context
    useEffect(() => {
        if (typeof audioEngine === 'object' && audioEngine !== null & user.isUser) {

            setDisplay( 'demo' );
            // setDisplay('intro');

        }
    }, [audioEngine, user])

    useEffect(() => {

        // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/

        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        // We listen to the resize event
        window.addEventListener('resize', () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });

    }, [] );




    function renderDisplay() {
        return displayFunctions[display]();
    }

    const displayFunctions = {
        'loading': function loading() {
            return (
                <>
                    <h2>Loading</h2>
                </>
            );
        },
        'audio-engine-failed': function audioEngineFailed() {
            return (
                <h2>Audio engine failed to load. Please try again with a modern browser.</h2>
            );
        },
        'user-failed': function userFailed() {
            return (
                <h2>No user.</h2>
            );
        },
        'splash' : function splash() {
            return (
                <>
                    <h2>Loading</h2>
                    
                </>
            );
        },
        'dashboard': function dashboard() {
            return (
                <button>Dashboard</button>
            );
        },
        // 'router' : function router() {
        //     if(user.isUser) {
        //         return (
        //             <>
        //                 { renderRouter() }           
        //             </>
        //         );
        //     }
        // },
        'demo': function demo() {

            if (user.isUser) {
                return (
                    <>
                        { renderDemo()}
                    </>
                );

            }

        }
    };

    // function renderRouter() {
    //     return (
    //         <>
    //             <GlobalStyles uiScheme={ draculaBuffy } />
    //             <div className="loss-app">  
    //                 <div className="loss-outer">


    //                 <Pane

    //                     forwardedKey="loss-utility"
    //                     forwardedRef={ appUtility }
    //                     extraClassName="extra"
    //                     orientation="horizontal"
    //                     childFlexGrow="shrink"
    //                     paneHeight="15vh"
    //                     paneWidth="auto"


    //                     >


    //                     </Pane>


    //                     <Pane

    //                         forwardedKey="loss-main"
    //                         extraClassName="extra"
    //                         orientation="horizontal"
    //                         paneHeight="70vh"
    //                         paneWidth="auto"


    //                     >
    //                         <Router
    //                             style={ { width: '100%' } }
    //                         >
    //                            <HearingLossSimulator 
    //                                     path="/"
    //                                     uiScheme={ uiScheme }
    //                                     user={ user }
    //                                     appUtility={ appUtility }
    //                                     appFooter={ appFooter }
    //                                     audioEngine={ audioEngine }
    //                                 ></HearingLossSimulator>
    //                         </Router>
    //                     </Pane>

    //                     <Pane

    //                         forwardedKey="loss-footer"
    //                         forwardedRef={ appFooter }
    //                         extraClassName="extra"
    //                         orientation="horizontal"
    //                         paneHeight="15vh"
    //                         paneWidth="auto"

    //                     >

    //                     </Pane>

    //                 </div>
    //             </div>
    //         </>
    //     );
    // }

    function renderAudioSwitch() {

      

        if (!audioResumed) {

            return (

                <>

                </>

            )

        }

    }

    // function renderSplash() {

    //     return (
    //         <>
    //             <Splash />
    //         </>
    //     );

    // }

    function renderDemo() {

        return (
            <>

                <IntroAudioPlayer
                    splashClosed={ splashClosed }
                    audioEngine={ audioEngine }
                    setMediaSource={ (mediaElement) => audioEngine.connectDestination( mediaElement )}
                ></IntroAudioPlayer>

                <HearingLossSimulator
                    splashClosed={ splashClosed }
                    path="/"
                    uiScheme={uiScheme}
                    user={user}
                    appUtility={appUtility}
                    appFooter={appFooter}
                    audioEngine={audioEngine}
                    portals={ props.portals }
                    refs={ props.refs }
                ></HearingLossSimulator>
            </>
        );

    }



    //  Main render
    /////////////////////////////////////////////////

    return (
        <>

            { renderDisplay()}

        </>
    );

}

export default Demo;
