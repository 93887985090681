/* 
 * audio-import-map.js
 */

// Audio Context
import { create as createAudioEngine } from './constructors/audioEngine';

// Modules
import { create as createGraphicEq } from './constructors/mod-graphicEq';

// Helpers
import { create as createBufferLoader } from './helpers/bufferLoader';

// Base constructors
import { create as createAENode } from './constructors/base/base-audio';
import { create as createAEModule } from './constructors/base/base-audio-mod';

// User Media
import { create as createUserMedia } from './constructors/mod-audioInput';

const audioConstructors = {
    // Audio Context
    audioEngine : createAudioEngine,

    //Modules
    graphicEq : createGraphicEq,
    createGraphicEq : createGraphicEq,

    //Helpers
    bufferLoader : createBufferLoader,

    // Base constructors
    AENode : createAENode,
    AEModule : createAEModule,

    // User Media
    userMedia : createUserMedia,
}

const AudioMap = {
    get : function get( name ) {
        return audioConstructors[name];
    }
};

export default AudioMap;

