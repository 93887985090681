import React, { useState, useEffect, useRef } from 'react';
import { css } from 'styled-components';

/*----------  Audio  ----------*/
import {freqBands} from '../../../../audio/config';


/*----------  Entities  ----------*/
import { getAudiogram } from '../../../../entities/audiograms/audiograms';


/*----------  Generators  ----------*/
import { createGraphData } from '../../../../generators/graphData';


/*----------  Data  ----------*/
import * as HLData from '../../../../data/hearingLossData';
import * as HLConfig from './HearingLossSimulatorConfig';


/*----------  Maps  ----------*/
import { colourMap } from '../../../../maps/colourMap';




/*----------  Hooks  ----------*/
import useKeyPress from '../../../../hooks/useKeyPress';



/*----------  Components  ----------*/
import Button from '../../../../components/buttons/Button';
import ButtonIcon from '../../../../components/buttons/ButtonIcon';
import AudioPlayer from '../../../../components/audio/AudioPlayer/AudioPlayer';
import GraphHLS from '../../../../components/graph/GraphHLS';
import SelectButtons from '../../../../components/selects/SelectButtons';

import ButtonGroupFromMap from '../../../../components/buttons/ButtonGroupFromMap';

//portals
import AppUtility from '../../../../components/portals/AppUtility';
import AppFooter from '../../../../components/portals/AppFooter';

// Pane
import Pane from '../../../../components/panes/Pane';
import PaneContainer from '../../../../components/panes/PaneContainer';



/*----------  Modals  ----------*/
import ChoiceModal from '../../../../components/modals/ChoiceModal';
// import AudiogramModal from '../../../../components/modals/AudiogramModal';





import Tour from 'reactour';




import AudioMap from '../../../../audio/audio-import-map';




import PortalFromDOM from '../../../../../loss-new/components/portals/PortalFromDOM';
import SelectImageButtonGroupFromMap from '../../../../../loss-new/components/buttons/SelectImageButtonGroupFromMap';
import SelectButton from '../../../../../loss-new/components/buttons/SelectButton';



export default
function HearingLossSimulator( props ) {

    //*******************************
    // App level required props
    //*******************************
    const audioEngine = props.audioEngine;
    const user = props.user;

    //*******************************
    // Tool specific config
    //*******************************
    const toolSetup = {
        name : 'HearingLossSimulator',
        modules : {
            input : 'graphicEq',
            'graphicEq' : 'output'
        },
        start : {
            programme : 'examples'
        },
    };

    const [ config, setConfig ] = useState(toolSetup);

    //*******************************
    // Tool state and storage
    //*******************************

    // Containers
    const [ state, setState ] = useState({
        audiogram : null,
        audiogramObj : null,
        audiogramMap : null,
        audiogramValuesRef : null,
        programmesLoaded : false,
        AEChain : false,
        graphRef : useRef(null),
        graphMode : 'single',
        audiogramInputGraphRef : useRef(null),
        graphArray : null,
        programme : null,
        programmeObject : null,
        programmeRender : null,
        runSafe : false
    });

    const [ isOpen, setIsOpen ] = useState({
        programmeMenu : false,
        audiogramInput : false,
        tour : false
    });



    const [ store, setStore ] = useState({
        // not plural becase of the storage interface below ~Line 87
        audiogram : {},
        
    });

    const [ AEChain, setAEChain ] = useState( null );

    const [ display, setDisplay ] = useState('loading');

    const [ graphData, setGraphData ] = useState( createGraphData( state.graphMode ) );

    
    /*----------  Subsection comment block  ----------*/
    const refProgrammeSelect = useRef(null);
    const refAudiogramSelect = useRef(null);
    const refGraph = useRef(null);
    const refHlsContent = useRef(null);
    


    // Keyboard keys
    const spaceBar = useKeyPress(' ');


    //*******************************
    // UI Update Refs
    //*******************************

    const programmeRef = props.refs.get( 'loss-ref-programme-select' );
    const presetRef = props.refs.get( 'loss-ref-preset-select' );

    const infoHeading = props.refs.get( 'loss-ref-info-heading' );
    const infoContent = props.refs.get( 'loss-ref-info-content' );
    const infoNav = props.refs.get( "loss-portal-info-nav" );

    //*******************************
    // //END UI Update Refs
    //*******************************



    // Functions 
    function stateSet(key, value) {
        return setState(prevState => {
            let newState = {
                ...prevState,
                [key] : value
            };
            if( key === 'programme' ) {
                // so we can access quickly, and state locally stored
                const programmeObject = HLConfig.programmes.get( value );
                

                newState.programmeObject = programmeObject;
                
            }

                
            
            // if( key === 'audiogram' ) {
            //     newState.audiogramObject = getAudiogram( value );
            // }
            return newState;
        });
    }
    function isOpenSet(key, value) {
        return setIsOpen(prevState => {
            let newState = {
                ...prevState,
                [key] : value
            };
            return newState;
        });
    }
    const storage = {
        store( thing, name, value ) {
            return setStore( prevStore => {
                let newStorage = {
                    ...prevStore
                };
                newStorage[ thing ][ name ] = {};
                newStorage[ thing ][ name ].store = value;
                return newStorage;
            });
        },
        delete( thing, name ) {
            const object = store[ thing ][ name ];
            if( object ) {
                object = null;
            }
        },
        get( thing, name ){
            return store[ thing ][ name ].store;
        },
        hasSaved( thing, name ) {
            
            if( !store[ thing ][ name ] ){
                return false;
            }

            const saved = store[ thing ][ name ].saved;

            if( !typeof saved === 'number' ){
                return false;
            }

            const now =     Date.now();

            if( now - saved > 0 ) {
                return saved;
            }
            else {
                return false; 
            }
        },
        setHasSaved( thing, name ) {
            const object = store[ thing ][ name ];

            object.saved = new Date.now();
        },
    };

    function stateSetStorageAudiogramValue( audiogram, options = { ear:'left', freq:'250',value:0} ){
        const curAudiogram = storage.get( 'audiogram', audiogram );

        let ear = options.ear;
        let freq = options.freq;
        let value = options.value;

        let newAudiogram = {
            ...curAudiogram
        };

        curAudiogram[ ear ][ freq ] = value;

        storage.store( 'audiogram', audiogram, newAudiogram );

    }

    
    

    //*******************************
    // Audio manipulation functions
    //*******************************

    function audioUpdateGraphicEqValue(value, freqBand, ear) {
        // TODO add a return value in the function 
        return AEChain.modules.get('graphicEq').handleInput(value, freqBand, ear);
    };
    function audioUpdateGraphicEqValues(audiogram) {
        // TODO add a return value in the function 
        let ears = HLData.ears;
        
        // handle the audio change first 
        for( let ear of ears ) {
            for( let freqBand of freqBands ) {
                try {
                    audioUpdateGraphicEqValue(audiogram[ear][freqBand], freqBand, ear);
                }
                catch(err) {
                    console.log(err);
                }
            }
        }
        
    }

    /////////////////////////////////
    // End/ Audio manipulation
    /////////////////////////////////


    //*******************************
    // App/Tool functions
    //*******************************

    function appChangeProgramme( programme ) {

        //1. Change State
        stateSet( 'programme', programme );
    }

    function appToggleLR( LR ) {

        // handle audio
        AEChain.modules.get('graphicEq').setSoloChannel(LR);

        if( LR === "l" || LR === "L" || LR === "left" || LR === "LEFT") {
        }
        if( LR === "r" || LR === "R" || LR === "right" || LR === "RIGHT") {
        }
        if( LR === "LR" ) {
        }

    }

    function appUserAudiogramInput( name, options = { ear:'left', freq:'250',value:0}) {

        let ear = options.ear;
        let freq = options.freq;
        let value = options.value;
        
        
        // handle the audio change first 
        audioUpdateGraphicEqValue(value, freq, ear);

        // store the new audiogram in state
        // pass in effect boolean so we don't need to alter 
        // state in two functions
        stateSetStorageAudiogramValue( name, options);

        // Handle Visuals
        calcGraphData();


    };

    function toggleGraphMode() {

        let newMode;

        if( state.graphMode == 'single' ) {

            newMode = 'all';

        }

        if( state.graphMode == 'all' ) {

            newMode = 'single';

        }

        stateSet( 'graphMode', newMode );
        

    }

    /////////////////////////////////
    // END: App/Tool functions
    /////////////////////////////////



    //*******************************
    // Drag and Drop files functions
    //*******************************

    // const handleDragEnter = e => {
    //     e.preventDefault();
    //     e.stopPropagation();

    //     dragDispatch({ type: 'SET_DROP_DEPTH', dropDepth: dragData.dropDepth + 1 });
    // };
    // const handleDragLeave = e => {
    //     e.preventDefault();
    //     e.stopPropagation();

    //     dragDispatch({ type: 'SET_DROP_DEPTH', dropDepth: dragData.dropDepth - 1 });
    //     if (dragData.dropDepth > 0) return
    //     dragDispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false })
    // };
    // const handleDragOver = e => {
    //     e.preventDefault();
    //     e.stopPropagation();

    //     e.dataTransfer.dropEffect = 'copy';
    //     dragDispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: true });
    // };
    // const handleDrop = e => {
    //     e.preventDefault();
    //     e.stopPropagation();

    //     let files = [...e.dataTransfer.files];

    //     if (files && files.length > 0) {
    //         const existingFiles = dragData.fileList.map(f => f.name)
    //         files = files.filter(f => !existingFiles.includes(f.name))

    //         dragDispatch({ type: 'ADD_FILE_TO_LIST', files });
    //         // e.dataTransfer.clearData();
    //         dragDispatch({ type: 'SET_DROP_DEPTH', dropDepth: 0 });
    //         dragDispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false });
    //     }

    //     console.log( 'fiko', files );
    // };


    // const dragDropReducer = (state, action) => {
    //     switch (action.type) {
    //         case 'SET_DROP_DEPTH':
    //             return { ...state, dropDepth: action.dropDepth }
    //         case 'SET_IN_DROP_ZONE':
    //             return { ...state, inDropZone: action.inDropZone };
    //         case 'ADD_FILE_TO_LIST':
    //             return { ...state, fileList: state.fileList.concat(action.files) };
    //         default:
    //             return state;
    //     }
    // };
    // const [dragData, dragDispatch] = React.useReducer(
    //     dragDropReducer, { dropDepth: 0, inDropZone: false, fileList: [] }
    // )



    /////////////////////////////////
    // END: Drag and Drop Files Functions
    /////////////////////////////////

























    //*************************
    // INIT
    //*************************

    useEffect( () => {

        init();

        async function init() {

            await initAudioChain();
            await initProgrammes();
            await initWebflowUI();
            
        }

        async function initProgrammes() {

            // // run through programmes and audiograms
            // // to create local storage of required
            // for( let [key, config] of HLConfig.programmes) {

            //     const audiograms = config.audiograms;

            //     for( let audiogram of audiograms ) {

            //         const options = HLConfig.options.get( audiogram );

            //         if( options.userInput ) {
            //             storage.save( 'audiogram', audiogram, getAudiogram( audiogram ) );
            //         }

            //     }
            // }

            stateSet( 'programmesLoaded', true );
            stateSet( 'AEChain', true );
            setDisplay( 'main' );
        }

        async function initAudioChain() {
            try {
                let AEChain = await audioEngine.createChain(config);

                // let AudioInput = AudioMap.get( 'userMedia' )( audioEngine );


                setAEChain(AEChain);
                // setAEModulesReady(true);
                
                
            }
            catch (err) {
                console.log(err);
            }
            
        }

        async function initWebflowUI() {
            // infoNav.innerHTML = '';
        }

    }, []);

    /////////////////////////////////
    // END: INIT
    /////////////////////////////////



    //*************************
    // Events/Effects
    //*************************

    // Once all dependencies are loaded let's get going
    useEffect(() => {

        if(state.AEChain || state.programmesLoaded ) {
            appChangeProgramme( toolSetup.start.programme );
        }
        else {
            // TODO show error
        }
        
        
    }, [state.AEChain, state.programmesLoaded]);


    // On programme change
    useEffect(() => {
        
        if( state.programmeObject ) {

            const defaultAudiogram = state.programmeObject.options.default;
            

            audiogramMap();


            // appChangeAudiogram( 'audiogram', defaultAudiogram );

            
            stateSet( 'audiogram', defaultAudiogram );
            
            
            
        }

        function audiogramMap() {
            // 1. Create Map of audiorams for the programme
            // 2. Redirect reference of userInput audiograms to 
            //    storage
            // so we can keep a record of where we are at
            const audiogramMap = new Map();


            

            for( const audiogram of state.programmeObject.options.audiograms ) {

                let options = HLConfig.options.get( audiogram );
                options.slug = audiogram;
                
                let audiogramObj;

                if( audiogram === 'sound-box-1' || audiogram === 'sound-box-2' || audiogram === 'sound-box-3' || audiogram === 'sound-box-4' ) {
                    audiogramObj = user.getAudiogram( audiogram );
                }
                else if( audiogram === 'my-unaided' || audiogram === 'my-aided' ) {
                    audiogramObj = user.getAudiogram( audiogram );
                }
                else {
                    audiogramObj = getAudiogram( audiogram );
                }


                let audiogramRef = audiogramObj;

                

                
                
                // Create storage for the user input audiograms
                if( options.userInput ) {

                    

                    if( !storage.hasSaved( 'audiogram', audiogram ) ) {
                        // TODO HANDLE API SAVING HERE
                    }

                    audiogramRef = storage.store( 'audiogram', audiogram, audiogramObj );


                }

                audiogramMap.set(
                    audiogram,
                    {
                        audiogram : audiogramObj,
                        options : options,
                        audiogramRef : audiogramRef
                    }
                );

            }

            const programmeTitle = programmeRef.querySelector( '.loss-select-choice' );
            const programmeImg = programmeRef.querySelector( '.loss-btn-img' );

            programmeTitle.innerHTML = state.programmeObject.options.title;
            programmeImg.src = state.programmeObject.options.img;

            
            stateSet('audiogramMap', audiogramMap);

            stateSet( 'programmeRender', true );

            
        }

    }, [ state.programme, state.programmeObject ])

    // On audiogram change
    useEffect(() => {

        if( state.audiogramMap ) {

            const audiogramState = state.audiogramMap.get( state.audiogram );

            audioUpdateGraphicEqValues( audiogramState.audiogram );
            
            
            let newRef = audiogramState.audiogram;

            stateSet( 'audiogramObj', audiogramState );

            

            if( audiogramState.options.userInput ) {

                newRef = storage.get( 'audiogram', state.audiogram );

            }
  
            
            calcGraphData();

            // Update ui from webflow (outside of react DOM)

            const presetTitle = presetRef.querySelector( '.loss-select-choice' );
            const presetImg = presetRef.querySelector( '.loss-btn-img' );

            presetTitle.innerHTML = audiogramState.options.name;
            presetImg.src = audiogramState.options.img;

            infoHeading.innerHTML = audiogramState.options.name;
            infoContent.innerHTML = audiogramState.options.help;



            stateSet( 'audiogramValuesRef', newRef );
            stateSet( 'runSafe', true );
            


        }
        

    }, [ state.audiogram, state.audiogramMap ])



    function calcGraphData() {
        let displayMode = state.graphMode;
        let graphMono = state.programmeObject.options.graphMono;

        

        let graphArray = [];
        
        graphData.setDisplayMode( displayMode );
        graphData.setGraphMono( graphMono );
        graphData.setCurrentDataSet( state.audiogram );
        graphData.setXAxis( 'Frequency', 'Hz', freqBands  );


        if( displayMode === 'single' ) {
            const audiogramObj = state.audiogramMap.get( state.audiogram );
            graphArray = graphData.createSetFromSingle( state.audiogram, audiogramObj );
        }
        else if ( displayMode === 'all') {
            graphArray = graphData.createSetFromMap( state.audiogramMap );
        }
        // graphArray = graphData.getSet();

        stateSet( 'graphArray', graphArray );
    }

    useEffect(() => {
        if( state.runSafe ){

        calcGraphData();
    }
    }, [ state.graphMode, state.runSafe ])
    
    useEffect(() => {
        if( props.splashClosed ){

            isOpenSet( 'tour', true );
        }
    }, [ props.splashClosed ])

    //*************************
    // RENDER Functions
    //*************************

    //TODO = create a breadcrumb component

    

    function renderLRControls() {
    }

    function renderGraph() {

        const audiogram = state.audiogramValuesRef;

        if( audiogram ){

            
            // let displayMode = state.programmeObject.options.graphDisplay;
            // let graphMono = state.programmeObject.options.graphMono;

            

            // let graphArray = [];
            
            // graphData.setDisplayMode( displayMode );
            // graphData.setGraphMono( graphMono );
            // graphData.setCurrentDataSet( state.audiogram );
            // graphData.setXAxis( 'Frequency', 'Hz', freqBands  );


            // if( displayMode === 'single' ) {
            //     const audiogramObj = state.audiogramMap.get( state.audiogram );
            //      graphArray = graphData.createSetFromSingle( state.audiogram, audiogramObj );
            // }
            // else if ( displayMode === 'all') {
            //      graphArray = graphData.createSetFromMap( state.audiogramMap );
            // }
            // // graphArray = graphData.getSet();

            return (
                <GraphHLS
                    graphRef={ state.graphRef } 
                    data={ state.graphArray } 
                    currentAudiogram={ state.audiogram }
                />
            );

        }

        
    }

    function renderAudiogramSelect() {

        return( 

            <SelectImageButtonGroupFromMap
                title="Select the programme"
                choiceMap={ state.audiogramMap }
                currentChoice={ state.audiogram }
                uiScheme={ props.uiScheme }
                onChoiceSelect={ (audiogram) => stateSet( 'audiogram', audiogram ) }
                buttonType="ButtonImage"
                orientation="vertical"
                type={ 'preset' }
            >

            </SelectImageButtonGroupFromMap>

        );

        // return(
        //     <SelectButtons
        //         flexAlignment="vertical"
        //         buttonAlignment="vertical"
        //         curSelection={ state.audiogram }
        //         selectionMap={ state.audiogramMap }
        //         audiogram={ state.audiogram }
        //         onAudiogramSelect={ (audiogram) => stateSet( 'audiogram', audiogram ) }
        //         btnStylin={ css`
        //             font-size: 15px;

        //             &.loss-active {
        //                 border-color: ${ colourMap.button.active.border }
        //             }
        //         `}
        //     >
        //     </SelectButtons>
        // );
    }



    function renderGraphMenu() {
        const audiogramObj = state.audiogramObj;
        function rGMode() {

            return (

                <div
                    className={ "loss-graph-top" }
                >

                    <button
                    onClick={ () => { toggleGraphMode() } }
                    >
                    


                        Graph Mode : { state.graphMode == 'single' ? 'Current Only' : 'All Audiograms' }
                    </button>
                    {/* <ButtonIcon

                        forwardedforwardedKey={ 'any' }
                        onClick={ () => { toggleGraphMode() } }
                        isActive={ false }
                        uiScheme={ props.uiScheme }
                        buttonStyle={ 'small' }
                        tabStyle="bottom"
                        orientation={ 'horizontal' }
                        extraClassName={ 'graph-btn' }
                        icon={ {
                            ligature : 'sine',
                            size : '24px'
                        } }

                        >

                        Graph Mode : { state.graphMode == 'single' ? 'Current Only' : 'All Audiograms' }

                        </ButtonIcon> */}
                </div>

            );

        }
        function rGUserInput() {

            if( audiogramObj.options.userInput ) {
                return (
                    <>
                        <Button 
                            extraClassName="loss-graph-menu-item w-inline-block"
                            onClick={ () => isOpenSet( 'audiogramInput', true ) }
                        >
                            <div className="icomoon graph-menu">sine</div>
                            <div>Edit the graph</div>
                        </Button> 
                        {/* <AudiogramModal
                            title="Select the programme"
                            freqBands={ freqBands }
                            graphRef={ state.audiogramInputGraphRef } 
                            currentAudiogram={ state.audiogram }
                            graphData={ state.graphArray } 
                            audiogramObj={ state.audiogramObj } 
                            audiogramValuesRef={ state.audiogramValuesRef }
                            setAudiogramValue={ ( ear, freqBand, value ) => appUserAudiogramInput( state.audiogram, { ear: ear, freq : freqBand, value : value} )}
                            isOpen={ isOpen.audiogramInput }
                            close={ () => isOpenSet( 'audiogramInput', false ) }
                        >
        
                        </AudiogramModal> */}

                        { rGMode() }

                    
                    </>
                );
            }

        }
        if( audiogramObj ) {
            
            return ( 

                <>
                {rGMode()}
                {/* {rGUserInput()} */}
                </>

            );

        }
        

        
        
    }


    function renderAudioPlayer() {
        return (
            <AudioPlayer
                audioEngine={ audioEngine }
                spaceBar={spaceBar}
                uiScheme={ props.uiScheme }
                portals={ props.portals }
                refs={ props.refs }
                // audioHTMLID={ config.source.htmlAudioElement}
                // audioRendered={(value) => setAudioHTMLRendered(value)}
                setMediaSource={ (mediaElement) => audioEngine.setSource('HearingLossSimulator', mediaElement)}
            />
        );
    }


    function renderText( group, thing ) {

        let string;

        if( state.audiogramObj ){
            if( group === 'audiogram' ) {

                if( thing === 'title' ){
                    string = state.audiogramObj.options.name;
                }

                if( thing === 'content' ) {
                    string = state.audiogramObj.options.help;
                }
                
            }
            if( group === 'programme' ) {

                if( thing === 'title' ){
                    string = state.programmeObject.options.title;
                }

                
            }

            return (
                <>
                    { string }
                </>
            );
        }
    }

    function renderProgrammeSelect() {

        return( 

            <SelectImageButtonGroupFromMap

                title="Select the programme"
                choiceMap={ HLConfig.programmes }
                currentChoice={ state.programme }
                uiScheme={ props.uiScheme }
                onChoiceSelect={ ( choice ) => appChangeProgramme( choice )}
                buttonType="ButtonImage"
                buttonStyle="tab"
                tabStyle="top"
                orientation="vertical"
                type={ "programme" }

            >

            </SelectImageButtonGroupFromMap>


        );

        // return(
        //     <>
        //         <Button
        //             onClick={ () => isOpenSet( 'programmeMenu', true ) }
        //             extraClassName={ "programme" }
        //             innerClassName={ "programme" }
        //         >
        //             <div className="loss-btn-title">Programme</div>
        //                 <div className="loss-btn-content">
        //                 <img
        //                     src={ state.programmeObject.options.img } 
        //                     loading="lazy"
        //                     id="w-node-b39263ac62bf-450bbc37" 
        //                     alt="" 
        //                     className="image-3"
        //                     style={ { width : "50px", height : "50px" } }
        //                 />
        //                 <div> { renderText( 'programme', 'title' ) }</div>
        //             </div>

        //         </Button>
               

        //         <ChoiceModal
        //                 title="Select the programme"
        //                 choiceMap={ HLConfig.programmes }
        //                 currentChoice={ state.programme }
        //                 uiScheme={ props.uiScheme }
        //                 onChoiceSelect={ ( choice ) => appChangeProgramme( choice.slug )}
        //                 isOpen={ isOpen.programmeMenu }
        //                 close={ () => isOpenSet( 'programmeMenu', false ) }
        //             >

        //             </ChoiceModal>

        //     </>





        // );
    }


    function renderInfoNav() {

        return(
            <SelectButton
                title={ "Give feedback" }
                subtitle={"Your response is highly valued"}
                href="https://losseiver.co.uk/software-feedback/"
                icon={ 'arrow5' }
                onClick={ () => {} }
                navType={ 'info' }
            ></SelectButton>
        );

    }
    


    function hls() {
        return (
            <> 
                
        
                        <PortalFromDOM
                            portalDOM={ props.portals.get( "loss-portal-programme-nav" ) }
                        >
                            { renderProgrammeSelect() }
                        </PortalFromDOM>

                      
                            

                        <PortalFromDOM
                            portalDOM={ props.portals.get( "loss-portal-preset-nav" ) }
                        >
                            { renderAudiogramSelect() }
                        </PortalFromDOM>


                        <PortalFromDOM
                            portalDOM={ props.portals.get( "loss-portal-audiogram" ) }
                        >
                             {
                                        renderGraphMenu()
                                        
                                    }
                                    {
                                        renderGraph()
                                    }
                        </PortalFromDOM>

                        <PortalFromDOM
                            portalDOM={ props.portals.get( "loss-portal-info-nav" ) }
                        >
                            { renderInfoNav() }
                        </PortalFromDOM>
                        

                      

                        

                            { renderAudioPlayer() }


                            <Tour
                                steps={tourSteps}
                                isOpen={isOpen.tour}
                                onRequestClose={() => isOpenSet( 'tour', false )} 
                            />

                

                
            </>
        );
    }




    //*************************
    // RENDER
    // (To follow, start at the bottom and work your way up
    // the file. Order of functions called goes, first at 
    // the bottom, last towards the top. 
    //*************************

    function mainRender() {
        switch( display ) {
            case 'loading' :
                return (
                    <h2>Loading</h2>
                );
            case 'main' :
                if( state.programmeRender ) {
                    return (
                        <>
                        { hls() }
                        </>
                    );
                }
            default : 
                return (
                    <h2>Loading</h2>
                );
        }
    }

    return (
        <>
            { mainRender() }
        </>
    );
    
    /////////////////////////////////
    // END: RENDER
    /////////////////////////////////

    


}

const tourSteps = [
    {
      selector: '',
      content: 'This is the Hearing Loss Simulator. Click the arrows below or use the left and right arrow keys on the keyboard to navigate through the walkthrough to find out what you will be using on the screen. To dive straight in, click the x or anywhere else on the screen.',
    },
    {
      selector: '#loss-ref-programme-select',
      content: 'First you will use this dropdown to select a programme which contains a collection of audiograms or presets.',
    },
    {
      selector: '#loss-ref-preset-select',
      content: 'Once you have selected a programme, use this dropdown to switch between audiograms/presets.',
    },
    {
      selector: '#hlt-graph',
      content: 'The graph here displays the audiograms to the dBHL scale. For more info please check out our other pages on the website.',
    },
    {
      selector: '#loss-ref-player-btn',
      content: 'This button will allow you to play and pause the audio.',
    },
    {
      selector: '#loss-ref-player-select',
      content: 'Select the audio source via this dropdown.',
    },
    {
      selector: '.loss-player-settings-dropdown',
      content: 'Playback settings can be found within this dropdown.',
    },
    {
      selector: '.loss-graph-top',
      content: 'This button toggles between whether to show all the audiograms at once on the graph, or just the current selected.',
    },
    {
      selector: '.loss-info-menu-toggle',
      content: 'Finally, once you are done use this menu to navigate away from the simulator. If you would like to spare some time to leave some feedback we would be very grateful. The introduction is complete, please use the x to close or click anywhere on the screen to get going.  ',
    },
  ]