export default
function SelectButton(props) {

    return (

        <a 
            href={ props.href }
            onClick={ props.onClick }
            className={`loss-${ props.navType }-nav-link w-inline-block `}
            rel="noopener"
            target="_blank"
        >
            <div className={`audio-select-icon-container ${props.type}`}>
            <div class="lindua icon-player-audio-select-link"> { props.icon } </div>
            </div>
            <div>
                <div className="menu-title">{props.title}</div>
                <div className="menu-subtext">{props.subtitle}</div>
            </div>
        </a>

    );

};