import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { flexMap } from '../../maps/flexMap';

const paneSpacer = '0';

const StyledPane = styled.div`

    padding: ${ paneSpacer };
    margin: ${ paneSpacer };
    max-width: 100vw;

    /* account below for the margin */
    height: ${ props => props.paneHeight ? `calc( ${ props.paneHeight } ${ paneSpacer > 0 ? (paneSpacer * 2) : '' } )` : '100%' };

    ${ 
        
        props => props.paneWidth ? 
        props.paneWidth === 'auto' ? css`
            width: auto;
        `
        : css`

            width: ${ props => props.paneWidth ? `calc( ${ props.paneWidth } ${ paneSpacer > 0 ? (paneSpacer * 2) : '' } )` : 'unset' };

        `
        : ''

    }

    display: flex;
    flex-direction: ${ props => props.orientation ? flexMap[ props.orientation ] : 'row' };
    justify-content: space-between;

    & > * {

        ${ props => props.childFlexGrow && css`
        
            flex-grow: ${ props.childFlexGrow === 'shrink' ? 0 : 1 };
            flex-shrink: 1;
            flex-basis: ${ props.childFlexGrow === 'shrink' ? 'auto' : '0%' };
        
        ` }

        

    }

`;

export default
function Pane( props ) {

    // const [ isHover, setIsHover ] = useState( false );


    return(
        <StyledPane
            forwardedKey={ props.key }
            ref={ props.forwardedRef }
            orientation={ props.orientation } 
            paneHeight={ props.paneHeight }
            paneWidth={ props.paneWidth }
            className={ `loss-pane ${ props.extraClassName }` }
            childFlexGrow={ props.childFlexGrow }
            // onMouseEnter={ () => setIsHover( true ) }
            // onMouseLeave={ () => setIsHover( false ) }
        >

            { props.children }

        </StyledPane>
    );
}