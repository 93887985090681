import AudioMap from '../audio-import-map';

export
function create(options) {

    // TODO
    //  - implement the ability to plugin items along the chain and subsequents shifts of others, etc. 

    let audioContext = null;

    let curChainNo = 0;
    let audioChains = new Map();
    let isPromiseEnabled = true;

    
    // The Functions
    async function createChain(config) {

        // config = {
        //     name: 'HearingLossSimulator',
        //     source : Object -> AudioNode,
        //     modules : {
        //         // input          : first module
        //         // first module   : second module
        //         // .....so on until 
        //         // nth module       : output
        //         input : 'GraphicEq',
        //         'GraphicEq' : 'output'
        //     }
        // }
        
        
        // create a chain and save in the map
        // 1. get audioNode object and create (invoke)
        let audioNode = AudioMap.get('AEModule')(publicAPI);
        let AEModules = new Map();
        let AEModulePromises = [];
        // Store this in the chain object
        audioChains.set(
            config.name,
            {
                name: config.name,
                id: curChainNo,
                config : config,
                audioNode : audioNode,
                modules : AEModules,
                modulesReady : false,
            }
        );
        
        curChainNo++;
        // END create chain
        
        // Setup local variables for ease
        
        let source = config.source;
        let modules = config.modules;

        if( config.input ) {
            audioNode.attachInput(config.input);
        }

        if( source ) {
            source.connect(audioNode.getNode('in'));
        }


        // Module plugin loop
        ///////////////////////////////////////////////

        // Setup local vars for module linking loop
        
        let loopStep = 'input'; //init to the input
        let areModules = true;
        let nextModule = modules[loopStep];
        let prevModule =  null;

        while (areModules) {

            let moduleConstructor = AudioMap.get(nextModule);

            if( moduleConstructor ) {
                let builtModule;
                try{
                    builtModule = await moduleConstructor(publicAPI, config);
                }
                catch( err ){
                    console.log(err);
                }
                
                AEModulePromises.push(builtModule);
                prevModule = builtModule;

                // set the module in the tools AEModules object
                // setAEModule(nextModule, builtModule);
    
                AEModules.set(nextModule, builtModule);
                if( loopStep === 'input' ) {
                    // hook up wet node of this tool
                    // and hook up to the next module
                    builtModule.attachInput(audioNode.getNode('inWet'))
                    
                }
                
            }
            else {
                // connect last module in the chain to this tools
                // output
                if( loopStep === 'out' || loopStep === 'output') {
                    prevModule.connect(audioNode.getNode('out'));
                    // close loop
                    areModules = false;
    
                    
                }
                
                
            }
            
            nextModule = modules[nextModule];
            loopStep = nextModule;
        }


        publicAPI.connectDestination(audioNode);

        return audioChains.get(config.name);
        // return 'fish';
    }

    function setSource(chain, node) {
        let theChain = audioChains.get(chain);
        if( theChain ) {
            // let ting = audioContext.createGain();
            // node.connect(ting);
            // ting.connect(theChain.audioNode.getNode('inWet'));
            node.connect(theChain.audioNode.getNode('in'));
        }
    }

    






    try {
        // const audioContextFunction = (
        //     AudioContext ||
        //     window.AudioContext ||
        //     window.webkitAudioContext ||
        //     window.mozAudioContext ||
        //     window.oAudioContext ||
        //     window.msAudioContext
        // );
        
        const audioContextFunction = ( window.AudioContext ||
            window.webkitAudioContext ||
            window.mozAudioContext ||
            window.oAudioContext ||
            window.msAudioContext);
        if(!window.AudioContext) {
            isPromiseEnabled = false;
        }
        audioContext = new audioContextFunction();
    } catch(e) {

        throw new Error('Browser Not Supported');
    }

	const publicAPI = {
        isPromiseEnabled : isPromiseEnabled,
        getAudioContext() {
            return audioContext;
        },
        connectDestination(node) {
            node.connect(audioContext.destination);
        },
        createChain(options) {
            return createChain(options);
        },
        setSource(chain, node) {
            return setSource(chain, node);
        },
        resume() {
            audioContext.resume();
        }
	}

	return publicAPI;
}
