import React, { useState, useEffect, useRef } from 'react';
import PortalFromDOM from './loss-new/components/portals/PortalFromDOM';

import VerticalSlider from './loss-new/components/input/range/VerticalSlider';

function App( props ) {

    const splash = props.refs.get( 'loss-ref-splash' );
    const closeSplash = props.refs.get( 'loss-ref-splash-btn' );

    closeSplash.addEventListener( 'click', () => {
        splash.remove();
    });


    // return (
    //         <div className="App">
                
    //             <Portal

    //                 portalDOM={ portals.getDOM( "loss-portal-audiogram" ) }

    //             >
    //                 Testing
    //                 {/* <Range

    //                     min={ -130 }
    //                     step={ 1 }
    //                     max={ 10 }
    //                     value={ 0 }
    //                     vertical={ true }

    //                 ></Range> */}
    //             </Portal>

    //         </div>
    // );
    return (
        <div className="App">

            
            
            
            <PortalFromDOM

                portalDOM={ props.portals.get( "loss-portal-audiogram" ) }

            >
                Testing
                <VerticalSlider

                    min={ -130 }
                    step={ 1 }
                    max={ 10 }
                    initValue={ 0 }

                ></VerticalSlider>

            </PortalFromDOM>

        </div>
    );
}

export default App;
