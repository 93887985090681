import { create as createAEModule } from '../constructors/base/base-audio-mod';
import { create as createBufferLoader } from '../helpers/bufferLoader';

export
async function create(audioEngine, newConfig) {

    //TODO make better
    const losseiverwp = window.losseiverwp;


    var config = {
        chainBuilt : false,
        isSoloed : false
    };

	// holds the underlying module code
    var AEModule = createAEModule(audioEngine);
    AEModule.mergeConfig(config);

    var publicAPI = Object(AEModule);

    // publicAPI.bypass(true);

    // eq setup, will have buffer prop when fetch resolved
    
    publicAPI.eqSetup = [
        { band: 'low', preGain: 1, filter: `${losseiverwp['react-url']}/audio/filters/lowv2.wav` },
        { band: '125', preGain: 1, filter: `${losseiverwp['react-url']}/audio/filters/125v2.wav` },
        { band: '250', preGain: 1, filter: `${losseiverwp['react-url']}/audio/filters/250v2.wav` },
        { band: '500', preGain: 1, filter: `${losseiverwp['react-url']}/audio/filters/500v2.wav` },
        { band: '1000', preGain: 1, filter: `${losseiverwp['react-url']}/audio/filters/1000v2.wav` },
        { band: '2000', preGain: 1, filter: `${losseiverwp['react-url']}/audio/filters/2000v2.wav` },
        { band: '8000', preGain: 1, filter: `${losseiverwp['react-url']}/audio/filters/8000v2.wav` },
        { band: '4000', preGain: 1, filter: `${losseiverwp['react-url']}/audio/filters/4000v2.wav` },
    ];
    // publicAPI.eqSetup = [
    //     { band: 'low', preGain: 1, filter: `../../assets/audio/filters/lowv2.wav` },
    //     { band: '125', preGain: 1, filter: `../../assets/audio/filters/125v2.wav` },
    //     { band: '250', preGain: 1, filter: `../../assets/audio/filters/250v2.wav` },
    //     { band: '500', preGain: 1, filter: `../../assets/audio/filters/500v2.wav` },
    //     { band: '1000', preGain: 1, filter: `../../assets/audio/filters/1000v2.wav` },
    //     { band: '2000', preGain: 1, filter: `../../assets/audio/filters/2000v2.wav` },
    //     { band: '8000', preGain: 1, filter: `../../assets/audio/filters/8000v2.wav` },
    //     { band: '4000', preGain: 1, filter: `../../assets/audio/filters/4000v2.wav` },
    // ];
    
    publicAPI.buildChain = async function buildChain() {

        // create and connect L + R and merge nodes together
        this.nodes.LNode = this.createGain(1);
        this.nodes.RNode = this.createGain(1);
        this.nodes.LRNode = this.createChannelMerger(2, "LRNode");

        this.nodes.LNode.connect(this.nodes.LRNode, 0, 0);
        this.nodes.RNode.connect(this.nodes.LRNode, 0, 1);

        this.nodes.LRNode.connect(this.getNode('out'));

        // setup freq bands
        // set previous band as input 
        let inNode = this.getNode('inWet');

        this.eqSetup.forEach( band => {
            let preGain = `pregain-${band.band}`; 
            let filter = `filter-${band.band}`; 
            let splitter = `splitter-${band.band}`; 
            let gainLeft = `gain-${band.band}-left`; 
            let gainRight = `gain-${band.band}-right`; 

            this.nodes[preGain] = this.createGain(band.preGain, preGain);
            
            this.nodes[filter] = this.createConvolver(band.buffer, filter);
            this.nodes[splitter] = this.createChannelSplitter(2, splitter);
            this.nodes[gainLeft] = this.createGain(1, gainLeft);
            this.nodes[gainRight] = this.createGain(1, gainRight);

            inNode
            .connect(this.nodes[`pregain-${band.band}`]);

            this.nodes[`pregain-${band.band}`]
            .connect(this.nodes[`filter-${band.band}`]);

            this.nodes[`filter-${band.band}`]
            .connect(this.nodes[`splitter-${band.band}`])    

            // To test bypassing the convolver Node
            // this.nodes[`pregain-${band.band}`]
            // .connect(this.nodes[`splitter-${band.band}`])            

            

            //L N.B -> 0 in connect denotes L
            this.nodes[`splitter-${band.band}`]
            .connect(this.nodes[`gain-${band.band}-left`], 0);
            
            //R N.B -> 0 in connect denotes R
            this.nodes[`splitter-${band.band}`]
            .connect(this.nodes[`gain-${band.band}-right`], 1);

            this.nodes[`gain-${band.band}-left`]
            .connect(this.nodes.LNode);
            
            this.nodes[`gain-${band.band}-right`]
            .connect(this.nodes.RNode);
        });

        this.config.chainBuilt = true;
    }

    publicAPI.changePreset = function changePreset(preset) {

        //TODO make this better and more secure, probably will fail
        var data = this.HLData.audiograms[preset];

        this.eqSetup.forEach( band => {
            if( band.band === 'low' ) {
                return;
            }

            // this.setGain(this.nodes[`gain-${band.band}-left`], data.left[band.band]);
            // this.setGain(this.nodes[`gain-${band.band}-right`], data.left[band.band]);

            this.handleInput(data.left[band.band], band.band, 'left');
            this.handleInput(data.right[band.band], band.band, 'right');

        });
    }
	

	

    // create new buffer loader
	var bufferLoader = createBufferLoader(audioEngine);

    // for each freq band, load convolution filters async 
    // assign to fre band object

    publicAPI.setupEq = async function setupEq(){
        let bufferProms = [];

        for ( const band of this.eqSetup) {
            try {
                let buffer = await bufferLoader.loadBuffer(band.filter);
                band.buffer = buffer;
            }
            catch (err) {
                throw new Error(err);
            }
        }
        // this.eqSetup.forEach(async(band) => {
        //     let buffer = await bufferLoader.loadBuffer(band.filter);
        //     bufferProms.push(buffer);
        //     // buffer
        //     // .then((buffer) => {
        //     //     band.buffer = buffer;
        //     // });
            
            
        // });
    }

    // function getBaseLog(x, y) {
    //     return Math.log(y) / Math.log(x);
    // }
    

    publicAPI.handleInput = function handleInput(value, freq, ear, save = false) {

        if( ear === 'L' || ear === 'l' || ear === 'left' || ear === 'Left') {
            ear = 'left';
        }
        if( ear === 'R' || ear === 'r' || ear === 'right' || ear === 'Right') {
            ear = 'right';
        }
            
        let nodeSelect = `gain-${freq}-${ear}`; 
        
        // TODO sort out this mess
         //Reverse value
        // value = Number(-value);
        value = Number(-value);
        var dbT = value;
        // Number now -10 -> 130
        value = value / 130;








        var fscaler = 0.001;
        // var fscaler = 999999999999999;
        if (dbT >= 65) {
            fscaler = 0.0000000000000009999999991;
        } else {}


        value = 1 - (Math.pow(fscaler, value) - 1) / (fscaler - 1);
        // value = 1 - (Math.pow(0.0000199999999999999,value)-1)/(0.0000199999999999999-1);
        // value = (Math.pow(0.000199999999999,value)-1)/(0.000199999999999-1);

        // 0.0000199999999999999

        value = value > 1 ? value / 1.5 : value;

        
        
        
        
        
        
        
        
        // value = 1 - value;




        this.setGain(this.nodes[nodeSelect], value);


    };

    publicAPI.setSoloChannel = function setSoloChannel(toSolo) {

        if(toSolo) {

            for( let band of this.eqSetup ) {
                if( toSolo === "l" || toSolo === "L" || toSolo === "left" || toSolo === "LEFT") {
                    this.setGain(this.nodes.LNode, 1);
                    this.setGain(this.nodes.RNode, 0);
                }
                if( toSolo === "r" || toSolo === "R" || toSolo === "right" || toSolo === "RIGHT") {
                    this.setGain(this.nodes.RNode, 1);
                    this.setGain(this.nodes.LNode, 0);
                    
                }
                if( toSolo === "LR" ) {
                    this.setGain(this.nodes.RNode, 1);
                    this.setGain(this.nodes.LNode, 1);
                }
                
            }

            this.config.isSoloed = true;
        }
        else {
            if( this.config.isSoloed ){
                for( let band of this.eqSetup ) {
                    this.setGain(this.nodes.RNode, 1);
                    this.setGain(this.nodes.LNode, 1);
                }
                this.config.isSoloed = false;
            }
        }

        
    }
    
    // now build audio chain
    let setup = publicAPI.setupEq();
    await setup;
    await publicAPI.buildChain();

	// loadedBuffers.then(response => {
	// 	BUFFERS = response;
	// });
	// apply a prefix using options.from
	
	return publicAPI;
}



