const portalRefs = [

    "loss-portal-programme-nav",
    "loss-portal-preset-nav",

    "loss-portal-audiogram",
    "loss-portal-info",
    "loss-portal-info-nav",

    "loss-portal-player-settings-nav",
    "loss-portal-player-select",


];

const domRefs = [

    'loss-ref-splash',
    'loss-ref-splash-heading',
    'loss-ref-splash-text',
    'loss-ref-splash-btn',
    'loss-ref-splash-audio',
    
    "loss-ref-player-btn",
    "loss-ref-player-select",

    "loss-ref-programme-select",
    "loss-ref-preset-select",

    "loss-ref-info-heading",
    "loss-ref-info-content",
    
    
];

const createDOMMap = function( idArray ) {

    const DOMMap = new Map();

    for( const DOMId of idArray ) {

        let DOMHandle = document.getElementById( DOMId );
    
        DOMMap.set( DOMId, DOMHandle );
    
    }

    return DOMMap;

}

export
const portals = createDOMMap( portalRefs );

export
const refs = createDOMMap( domRefs );
