// import { yellow } from "@material-ui/core/colors";

export
const uiScheme = {

    background : '#fff',
    text : '#222',

    textShadow : ( colour ) => `-1px 1px 0 ${ colour }`,
    boxShadow : ( colour ) => `-3px 3px 0 0 ${ colour }`,

    padding : {

        buttons : '1vh',
        buttonGroup : '1vh',
        
    },

    margin : {

        buttonGroup : '1vh',

    },

    tabs : {

        borderColour : 'transparent',
        background: 'green',

        active : {

            background: 'yellow',

        }

    },

    buttons : {

        default : {

            background : '#fff',
            text : '#222',
            border : '#E7E7E7',
            textShadow : '#E7E7E7',
            boxShadow : '#fff',
            borderRadius: '10px',

            title : {
                
                background : '#222',
                text : '#fff',

            },

            hover : {
                background : '#fff',
                text : '#222',
                border : '#222',
                textShadow : '#E7E7E7',
                boxShadow : '#E7E7E7',

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

            },

            active : {
                background : '#fff',
                text : '#222',
                border : '#222',
                textShadow : '#E7E7E7',
                boxShadow : '#E7E7E7',

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

                hover : {
                    background : '#fff',
                    text : '#222',
                    border : '#222',
                    textShadow : '#E7E7E7',
                    boxShadow : '#E7E7E7',

                    title : {
                
                        background : '#222',
                        text : '#fff',
        
                    },
                },

            },

        },
        small : {

            background : '#fff',
            text : '#222',
            border : '#E7E7E7',
            textShadow : '#E7E7E7',
            boxShadow : '#fff',
            borderRadius: '10px',
            "font-size" : '1rem',

            title : {
                
                background : '#222',
                text : '#fff',

            },

            hover : {
                background : '#fff',
                text : '#222',
                border : '#222',
                textShadow : '#E7E7E7',
                boxShadow : '#E7E7E7',

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

            },

            active : {
                background : '#fff',
                text : '#222',
                border : '#222',
                textShadow : '#E7E7E7',
                boxShadow : '#E7E7E7',

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

                hover : {
                    background : '#fff',
                    text : '#222',
                    border : '#222',
                    textShadow : '#E7E7E7',
                    boxShadow : '#E7E7E7',

                    title : {
                
                        background : '#222',
                        text : '#fff',
        
                    },
                },

            },

        },
        tab : {

            background : '#fff',
            text : '#222',
            border : '#222',
            textShadow : '#E7E7E7',
            boxShadow : '#E7E7E7',

            title : {
                
                background : '#222',
                text : '#fff',

            },

            hover : {
                background : '#fff',
                text : '#222',
                border : '#222',
                textShadow : '#E7E7E7',
                boxShadow : '#E7E7E7',

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

            },

            active : {
                background : '#f9f9f9',
                text : '#222',
                border : '#222',
                textShadow : '#E7E7E7',
                boxShadow : '#E7E7E7',

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

                hover : {
                    background : '#fff',
                    text : '#222',
                    border : '#222',
                    textShadow : '#E7E7E7',
                    boxShadow : '#E7E7E7',

                    title : {
                
                        background : '#222',
                        text : '#fff',
        
                    },
                },

            },

        },

        play : {

            background : '#47b157',
            text : '#fff',
            border : '#47b157', 
            textShadow : "#1e7847",
            boxShadow : "#1e7847",

            title : {
                
                background : '#222',
                text : '#fff',

            },

            hover : {

                background : '#47b157',
                text : '#fff',
                border : '#47b157', 
                textShadow : "#1e7847",
                boxShadow : "#1e7847",

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

            },

            active : {

                background : '#47b157',
                text : '#fff',
                border : '#47b157', 
                textShadow : "#1e7847",
                boxShadow : "#1e7847",

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

                hover : {

                    background : '#47b157',
                    text : '#fff',
                    border : '#47b157', 
                    textShadow : "#1e7847",
                    boxShadow : "#1e7847",

                    title : {
                
                        background : '#222',
                        text : '#fff',
        
                    },
                    
                }
                
            }
            
        },
        
        repeat : {

            background : '#ffb347',
            text : '#fff',
            border : '#ffb347',
            textShadow : "#eb9d2f",
            boxShadow : "#eb9d2f",

            title : {
                
                background : '#222',
                text : '#fff',

            },

            hover : {

                background : '#eeb347',
                text : '#fff',
                border : '#47b157', 
                textShadow : "#eb9d2f",
                boxShadow : "#eb9d2f",

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

            },

            active : {

                background : '#47b157',
                text : '#fff',
                border : '#47b157', 
                textShadow : "#1e7847",
                boxShadow : "#1e7847",

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

                hover : {

                    background : '#47b157',
                    text : '#fff',
                    border : '#47b157', 
                    textShadow : "#1e7847",
                    boxShadow : "#1e7847",

                    title : {
                
                        background : '#222',
                        text : '#fff',
        
                    },
                    
                }
                
            }
            
        },
        repeat : {

            background : '#ffb347',
            text : '#fff',
            border : '#ffb347',
            textShadow : "#eb9d2f",
            boxShadow : "#eb9d2f",

            title : {
                
                background : '#222',
                text : '#fff',

            },

            hover : {

                background : '#eeb347',
                text : '#fff',
                border : '#47b157', 
                textShadow : "#eb9d2f",
                boxShadow : "#eb9d2f",

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

            },

            active : {

                background : '#47b157',
                text : '#fff',
                border : '#47b157', 
                textShadow : "#1e7847",
                boxShadow : "#1e7847",

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

                hover : {

                    background : '#47b157',
                    text : '#fff',
                    border : '#47b157', 
                    textShadow : "#1e7847",
                    boxShadow : "#1e7847",

                    title : {
                
                        background : '#222',
                        text : '#fff',
        
                    },
                    
                }
                
            }
            
        },
        
        restart : {

            background : '#f5f5f5',
            text : "#333333",
            border : "#f5f5f5",
            textShadow : "#eb9d2f",
            boxShadow : "#eb9d2f",

            title : {
                
                background : '#222',
                text : '#fff',

            },

            hover : {

                background : "#ee6961",
                text : '#fff',
                border : '#47b157', 
                textShadow : "#b9b8b8",
                boxShadow : "#b9b8b8",

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

            },

            active : {

                background : null,
                text : null,
                border : null,
                textShadow : null,
                boxShadow : null,

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

                hover : {

                    background : null,
                    text : null,
                    border : null,
                    textShadow : null,
                    boxShadow : null,

                    title : {
                
                        background : '#222',
                        text : '#fff',
        
                    },
                    
                }
                
            }
            
        },
        
        audioSource : {

            background : '#f5f5f5',
            text : "#333333",
            border : "#f5f5f5",
            textShadow : "#eb9d2f",
            boxShadow : "#eb9d2f",

            title : {
                
                background : '#222',
                text : '#fff',

            },

            hover : {

                background : "#ee6961",
                text : '#fff',
                border : '#47b157', 
                textShadow : "#b9b8b8",
                boxShadow : "#b9b8b8",

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

            },

            active : {

                background : null,
                text : null,
                border : null,
                textShadow : null,
                boxShadow : null,

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

                hover : {

                    background : null,
                    text : null,
                    border : null,
                    textShadow : null,
                    boxShadow : null,

                    title : {
                
                        background : '#222',
                        text : '#fff',
        
                    },
                    
                }
                
            }
            
        },
        
        choice : {

            background : '#f5f5f5',
            text : "#333333",
            border : "#f5f5f5",
            textShadow : "#eb9d2f",
            boxShadow : "#eb9d2f",

            title : {
                
                background : '#222',
                text : '#fff',

            },

            hover : {

                background : "#ee6961",
                text : '#fff',
                border : '#47b157', 
                textShadow : "#b9b8b8",
                boxShadow : "#b9b8b8",

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

            },

            active : {

                background : null,
                text : null,
                border : null,
                textShadow : null,
                boxShadow : null,

                title : {
                
                    background : '#222',
                    text : '#fff',
    
                },

                hover : {

                    background : null,
                    text : null,
                    border : null,
                    textShadow : null,
                    boxShadow : null,

                    title : {
                
                        background : '#222',
                        text : '#fff',
        
                    },
                    
                }
                
            }
            
        },

        

    },

    audiogram : {

        normal : 'green',
        mild: 'blue',
        moderate: 'blue',
        high     : 'red',
        severe : 'black'

    },

}